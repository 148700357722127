<template>
    <v-container fluid>
        <v-row cols="12" class="d-flex justify-space-around ">
            <v-col cols="12">
                <p class="q-display-2 q_dark_gray--text">Real Estate Assets</p>
            </v-col>
        </v-row>
        <v-card cols="12" class="pa-5 ma-2 mt-7 d-flex flex-wrap">
            <v-col cols="12" class="flex-shrink-0">
                <p class="title">Property Values</p>
                <p>What is the value of your home and any other real estate you own?</p>
            </v-col>
            <v-card flat cols="12" sm="3" class="pa-5 ma-2 flex-shrink-1">
                <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-title>Primary Residence</v-list-item-title>
                        <v-list-item-subtitle>
                            <v-text-field v-model="lead.RealEstate.PrimaryHomeValue" prepend-icon="fas fa-dollar-sign" label="home value" @blur="updateFormat('PrimaryHomeValue')" @focus="updateFormat('PrimaryHomeValue')"></v-text-field>
                            <v-text-field v-model="lead.RealEstate.PrimaryHomeLender" prepend-icon="fas fa-university" label="lender"></v-text-field>
                            <v-text-field v-model="lead.RealEstate.PrimaryHomeRate" prepend-icon="fas fa-percentage" label="loan rate"></v-text-field>
                            <v-text-field v-model="lead.RealEstate.PrimaryHomeTerm" prepend-icon="fas fa-calendar-alt" label="loan term"></v-text-field>
                            <v-text-field v-model="lead.RealEstate.PrimaryHomeLoanAmount" prepend-icon="fas fa-dollar-sign" label="loan amount" @blur="updateFormat('PrimaryHomeLoanAmount')" @focus="updateFormat('PrimaryHomeLoanAmount')"></v-text-field>
                            <v-text-field v-model="lead.RealEstate.PrimaryHomeLoanBalance" prepend-icon="fas fa-dollar-sign" label="loan balance" @blur="updateFormat('PrimaryHomeLoanBalance')" @focus="updateFormat('PrimaryHomeLoanBalance')"></v-text-field>
                            <v-text-field v-model="lead.RealEstate.PrimaryHomePayment" prepend-icon="fas fa-dollar-sign" label="monthly payment" @blur="updateFormat('PrimaryHomePayment')" @focus="updateFormat('PrimaryHomePayment')"></v-text-field>
                            <v-text-field v-model="lead.RealEstate.PrimaryHomeAccelerated" prepend-icon="fas fa-dollar-sign" label="accelerated" @blur="updateFormat('PrimaryHomeAccelerated')" @focus="updateFormat('PrimaryHomeAccelerated')"></v-text-field>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-card>
            </v-col>
            <v-divider class="mx-4" vertical inset></v-divider>
            <v-card flat cols="12" sm="3" class="pa-5 ma-2 flex-shrink-1">
                <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-title>First Additional Property</v-list-item-title>
                        <v-list-item-subtitle>
                            <v-text-field v-model="lead.RealEstate.AdditionalProperty1Value" prepend-icon="fas fa-dollar-sign" label="additional home value" @blur="updateFormat('AdditionalProperty1Value')" @focus="updateFormat('AdditionalProperty1Value')"></v-text-field>
                            <v-text-field v-model="lead.RealEstate.AdditionalProperty1Lender" prepend-icon="fas fa-university" label="lender"></v-text-field>
                            <v-text-field v-model="lead.RealEstate.AdditionalProperty1Rate" prepend-icon="fas fa-percentage" label="loan rate"></v-text-field>
                            <v-text-field v-model="lead.RealEstate.AdditionalProperty1Term" prepend-icon="fas fa-calendar-alt" label="loan term"></v-text-field>
                            <v-text-field v-model="lead.RealEstate.AdditionalProperty1LoanAmount" prepend-icon="fas fa-dollar-sign" label="loan amount" @blur="updateFormat('AdditionalProperty1LoanAmount')" @focus="updateFormat('AdditionalProperty1LoanAmount')"></v-text-field>
                            <v-text-field v-model="lead.RealEstate.AdditionalProperty1LoanBalance" prepend-icon="fas fa-dollar-sign" label="loan balance" @blur="updateFormat('AdditionalProperty1LoanBalance')" @focus="updateFormat('AdditionalProperty1LoanBalanceAdditionalProperty1LoanBalance')"></v-text-field>
                            <v-text-field v-model="lead.RealEstate.AdditionalProperty1Payment" prepend-icon="fas fa-dollar-sign" label="monthly payment" @blur="updateFormat('AdditionalProperty1Payment')" @focus="updateFormat('AdditionalProperty1Payment')"></v-text-field>
                            <v-text-field v-model="lead.RealEstate.AdditionalProperty1Accelerated" prepend-icon="fas fa-dollar-sign" label="accelerated" @blur="updateFormat('AdditionalProperty1Accelerated')" @focus="updateFormat('AdditionalProperty1Accelerated')"></v-text-field>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-card>
            <v-divider class="mx-4" vertical inset></v-divider>
            <v-card flat cols="12" sm="3" class="pa-5 ma-2 flex-shrink-1">
                <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-title>Second Additional Property</v-list-item-title>
                        <v-list-item-subtitle>
                            <v-text-field v-model="lead.RealEstate.AdditionalProperty2Value" prepend-icon="fas fa-dollar-sign" label="additional home value" @blur="updateFormat('AdditionalProperty2Value')" @focus="updateFormat('AdditionalProperty2Value')"></v-text-field>
                            <v-text-field v-model="lead.RealEstate.AdditionalProperty2Lender" prepend-icon="fas fa-university" label="lender"></v-text-field>
                            <v-text-field v-model="lead.RealEstate.AdditionalProperty2Rate" prepend-icon="fas fa-percentage" label="loan rate"></v-text-field>
                            <v-text-field v-model="lead.RealEstate.AdditionalProperty2Term" prepend-icon="fas fa-calendar-alt" label="loan term"></v-text-field>
                            <v-text-field v-model="lead.RealEstate.AdditionalProperty2LoanAmount" prepend-icon="fas fa-dollar-sign" label="loan amount" @blur="updateFormat('AdditionalProperty2LoanAmount')" @focus="updateFormat('AdditionalProperty2LoanAmount')"></v-text-field>
                            <v-text-field v-model="lead.RealEstate.AdditionalProperty2LoanBalance" prepend-icon="fas fa-dollar-sign" label="loan balance" @blur="updateFormat('AdditionalProperty2LoanBalance')" @focus="updateFormat('AdditionalProperty2LoanBalance')"></v-text-field>
                            <v-text-field v-model="lead.RealEstate.AdditionalProperty2Payment" prepend-icon="fas fa-dollar-sign" label="monthly payment" @blur="updateFormat('AdditionalProperty2Payment')" @focus="updateFormat('AdditionalProperty2Payment')"></v-text-field>
                            <v-text-field v-model="lead.RealEstate.AdditionalProperty2Accelerated" prepend-icon="fas fa-dollar-sign" label="accelerated" @blur="updateFormat('AdditionalProperty2Accelerated')" @focus="updateFormat('AdditionalProperty2Accelerated')"></v-text-field>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-card>
        </v-card>
        <v-card cols="12" class="pa-5 ma-2 mt-7 d-flex flex-wrap">
            <v-col cols="12" class="flex-shrink-0">
                <p class="title">Liabilities</p>
                <p>Including mortgages, higher education for your children, credit card debt, auto loans, and other liabilities which would need to be paid in the event of a death.</p>
            </v-col>
            <!--
            <v-card cols="12" flat>
                <v-list-item two-line>
                    <v-list-item-content>s
                        <v-list-item-title>Mortgage Balance</v-list-item-title>
                        <v-list-item-subtitle>
                            <v-text-field v-model="lead.RealEstate.MortgageBalance" number prepend-icon="fas fa-dollar-sign" label="sum for all properties"></v-text-field>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-card>
            </v-col>
            <v-card cols="12" sm="4" flat>
                <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-title>Monthly Mortgage Payments</v-list-item-title>
                        <v-list-item-subtitle>
                            <v-text-field v-model="lead.RealEstate.MonthlyMortgagePayment" number prepend-icon="fas fa-dollar-sign" label="sum for all properties"></v-text-field>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-card>
        -->
            <v-card cols="12" sm="4" flat>
                <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-title>Additional Debt</v-list-item-title>
                        <v-list-item-subtitle>
                            <v-text-field v-model="lead.RealEstate.AdditionalDebt" prepend-icon="fas fa-dollar-sign" label="sum CC, vehicles, additional loans" @blur="updateFormat('AdditionalDebt')" @focus="updateFormat('AdditionalDebt')"></v-text-field>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <p class="title"></p>
            </v-card>
            <v-card cols="12" sm="4" flat>
                <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-title>Future Funds</v-list-item-title>
                        <v-list-item-subtitle>
                            <v-text-field v-model="lead.RealEstate.FutureFunds" prepend-icon="fas fa-dollar-sign" label="tuition, home updates, etc" @blur="updateFormat('FutureFunds')" @focus="updateFormat('FutureFunds')"></v-text-field>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-card>
            <v-card cols="12" sm="4" flat>
                <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-title>Final Expense</v-list-item-title>
                        <v-list-item-subtitle>
                            <v-text-field v-model="lead.RealEstate.FinalExpense" prepend-icon="fas fa-dollar-sign" label="$10,000 suggested min" @blur="updateFormat('FinalExpense')" @focus="updateFormat('FinalExpense')"></v-text-field>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-card>
        </v-card>
    </v-container>
</template>
<script>
import { formatField } from '@/components/utils/FormatNeedsAnalysisFields'
export default {
    props: ['lead'],
    data() {
        return {
            isPrimaryHomeValueActive: false
        }
    },
    methods: {
        updateFormat: function(fieldName){
           this.lead.RealEstate[fieldName] = formatField(fieldName,this.lead.RealEstate)
        }
    }
}

</script>
