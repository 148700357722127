<template>
    <v-container fluid>
        <v-row cols="12" class="d-flex justify-space-around ">
            <v-col cols="12">
                <p class="q-display-2 q_dark_gray--text">Debt</p>
            </v-col>
        </v-row>
        <v-row class="">
            <!--credit Card Debt -->
            <v-col cols="12" sm="3" class="pa-5  mt-7 ">
                <div class="">
                    <h4 class="q_dark_gray--text">Credit Card Debt</h4>
                </div>
                <v-row>
                    <v-col>
                        <v-simple-table style="background: transparent;">
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Type
                                    </th>
                                    <th class="text-left">
                                        Overpayment
                                    </th>
                                </tr>
                            </thead>
                        </v-simple-table>
                        <v-card cols="12" flat class="px-4 q-alt-rows" v-for="(asset, key) in debt.CreditCardDebt" :key="key">
                            <v-row>
                                <v-simple-table style="background: transparent;" width="100%">
                                    <tbody>
                                        <tr>
                                            <td class="px-5">
                                                <v-text-field v-model="asset.current_value" prepend-icon="fas fa-file-invoice-dollar" label="Monthly Payment"></v-text-field>
                                            </td>
                                            <td>
                                                <v-checkbox v-model="asset.Overpayment" color="#6ecf93" value="1" hide-details class="px-0 mt-n1" fluid></v-checkbox>
                                            </td>
                                            <td>
                                                <v-btn fab icon color="red" @click="deleteCC(key)">
                                                    <v-icon>fas fa-times-circle</v-icon>
                                                </v-btn>
                                            </td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row class="">
                    <v-btn depressed text right color="primary" @click="addCC">Add a credit card
                        <v-icon right>fas fa-plus-circle</v-icon>
                    </v-btn>
                </v-row>
            </v-col>
            <!--/Credit Card Debt -->
            <!--Student Loan Debt -->
            <v-col cols="12" sm="3" class="pa-5  mt-7 ">
                <div class="">
                    <h4 class="q_dark_gray--text">Student Loan Debt</h4>
                </div>
                <v-row>
                    <v-col>
                        <v-simple-table style="background: transparent;" width="100%">
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Type
                                    </th>
                                    <th class="text-left">
                                        Overpayment
                                    </th>
                                </tr>
                            </thead>
                        </v-simple-table>
                        <v-card cols="12" flat class="px-4 q-alt-rows" v-for="(asset, key) in debt.StudentLoanDebt" :key="key">
                            <v-row>
                                <v-simple-table style="background: transparent;" width="100%">
                                    <tbody>
                                        <tr>
                                            <td class="px-5">
                                                <v-text-field v-model="asset.current_value" prepend-icon="fas fa-file-invoice-dollar" label="Monthly Payment"></v-text-field>
                                            </td>
                                            <td>
                                                <v-checkbox v-model="asset.Overpayment" color="#6ecf93" value="1" hide-details class="px-0 mt-n1" fluid></v-checkbox>
                                            </td>
                                            <td>
                                                <v-btn fab icon color="red" @click="deleteStudentLoanDebt(key)">
                                                    <v-icon>fas fa-times-circle</v-icon>
                                                </v-btn>
                                            </td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row class="">
                    <v-btn depressed text right color="primary" @click="addStudentLoanDebt">Add a loan
                        <v-icon right>fas fa-plus-circle</v-icon>
                    </v-btn>
                </v-row>
            </v-col>
            <!--/Student Loan Debt -->
            <!--Personal Debt -->
            <v-col cols="12" sm="3" class="pa-5  mt-7 ">
                <div class="">
                    <h4 class="q_dark_gray--text">Personal Debt</h4>
                </div>
                <v-row>
                    <v-col>
                        <v-simple-table style="background: transparent;">
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Type
                                    </th>
                                    <th class="text-left">
                                        Overpayment
                                    </th>
                                </tr>
                            </thead>
                        </v-simple-table>
                        <v-card cols="12" flat class="px-4 q-alt-rows" v-for="(asset, key) in debt.PersonalDebt" :key="key">
                            <v-row>
                                <v-simple-table style="background: transparent;">
                                    <tbody>
                                        <tr>
                                            <td class="px-5">
                                                <v-text-field v-model="asset.current_value" prepend-icon="fas fa-file-invoice-dollar" label="Monthly Payment"></v-text-field>
                                            </td>
                                            <td>
                                                <v-checkbox v-model="asset.Overpayment" color="#6ecf93" value="1" hide-details class="px-0 mt-n1" fluid></v-checkbox>
                                            </td>
                                            <td>
                                                <v-btn fab icon color="red" @click="deletePersonalDebt(key)">
                                                    <v-icon>fas fa-times-circle</v-icon>
                                                </v-btn>
                                            </td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row class="">
                    <v-btn depressed text right color="primary" @click="addPersonalDebt">Add personal debt
                        <v-icon right>fas fa-plus-circle</v-icon>
                    </v-btn>
                </v-row>
            </v-col>
            <!--/Personal Debt -->
            <!--Car Loan Debt -->
            <v-col cols="12" sm="3" class="pa-5  mt-7 ">
                <div class="">
                    <h4 class="q_dark_gray--text">Car Loan Debt</h4>
                </div>
                <v-row>
                    <v-col>
                        <v-simple-table style="background: transparent;">
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Type
                                    </th>
                                    <th class="text-left">
                                        Overpayment
                                    </th>
                                </tr>
                            </thead>
                        </v-simple-table>
                        <v-card cols="12" flat class="px-4 q-alt-rows" v-for="(asset, key) in debt.CarLoanDebt" :key="key">
                            <v-row>
                                <v-simple-table style="background: transparent;">
                                    <tbody>
                                        <tr>
                                            <td class="px-5">
                                                <v-text-field v-model="asset.current_value" prepend-icon="fas fa-file-invoice-dollar" label="Monthly Payment"></v-text-field>
                                            </td>
                                            <td>
                                                <v-checkbox v-model="asset.Overpayment" color="#6ecf93" value="1" hide-details class="px-0 mt-n1" fluid></v-checkbox>
                                            </td>
                                            <td>
                                                <v-btn fab icon color="red" @click="deleteCarLoanDebt(key)">
                                                    <v-icon>fas fa-times-circle</v-icon>
                                                </v-btn>
                                            </td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row class="">
                    <v-btn depressed text right color="primary" @click="addCarLoanDebt">Add a car loan
                        <v-icon right>fas fa-plus-circle</v-icon>
                    </v-btn>
                </v-row>
            </v-col>
            <!--/Car Loan Debt -->
        </v-row>
    </v-container>
</template>
<script>
import { formatField } from '@/components/utils/FormatNeedsAnalysisFields'
export default {
    data: () => {
        return {
            ExpenseTypes: [
                "Mortgage", "Car", "Utilties", "Other Insurances", "Credit Card Debt", "Other"
            ],
            DebtTypes: [
                "Credit Card", "Student Loan", "Personal", "Car Loan"
            ]
        }
    },
    mounted: function() {

    },
    methods: {
        addCC: function() {
            this.debt.CreditCardDebt.push({
                current_value: 0,
                Overpayment: null,
            })
        },
        deleteCC: function(key) {
            this.debt.CreditCardDebt.splice(key, 1)
        },
        addStudentLoanDebt: function() {
            this.debt.StudentLoanDebt.push({
                current_value: 0,
                Overpayment: null,
            })
        },
        deleteStudentLoanDebt: function(key) {
            this.debt.StudentLoanDebt.splice(key, 1)
        },
        addPersonalDebt: function() {
            this.debt.PersonalDebt.push({
                current_value: 0,
                Overpayment: null,
            })
        },
        deletePersonalDebt: function(key) {
            this.debt.PersonalDebt.splice(key, 1)
        },
        addCarLoanDebt: function() {
            this.debt.CarLoanDebt.push({
                current_value: 0,
                Overpayment: null,
            })
        },
        deleteCarLoanDebt: function(key) {
            this.debt.CarLoanDebt.splice(key, 1)
        },
    },
    props: ['debt'],
    watch: {
        'debt': function(newV) {

        }
    }
}

</script>
