<template>
	<v-radio-group v-if="doShow" v-model="mValue" :disabled="disabled || loading" hide-details class="mt-2">
		<v-radio
			v-for="option in options" :key="option.value"
			:value="option.value"
			:label="option.label"
			:color="color"
		/>
	</v-radio-group>
</template>

<script>
import QuilityAPI from '@/store/API/QuilityAPI'
import Level from '@/store/Models/AgentManagement/Level'

const leadershipLevelsDefaults = [
    'Managing Partner',
    'Senior Partner',
    'Associate Partner',
    'Executive Vice President',
    'Senior Vice President',
    'Managing Vice President',
    'Regional Agency Director',
    'Agency Director',
    'Agency Owner',
    'Key Leader',
    'Team Leader',
    'Sales Representative',
    'SFG',
    'Asurea',
    'Wholesale Agency',
    'Tenant',
    'Quility',
]

const stats = {
	totalAgency: 'TotalAgency',
	baseshop: 'Baseshop',
	personal: 'PersonalProduction',
}

const defaultOptions = [
	{ label: 'Total Agency', value: stats.totalAgency },
	{ label: 'Baseshop', value: stats.baseshop },
	{ label: 'Personal', value: stats.personal },
]

export default {
    props: {
		value: {
			type: String,
			default: stats.personal,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		color: {
			type: String,
			default: 'primary',
		},
		agent: { type: Object },
	},
	data () {
		return {
			loading: false,
		}
	},
	created () {
		this.getLevels()
	},
    computed: {
		mValue: {
			get () { return this.value },
			set (val) { this.$emit('input', val) },
		},
		leadershipLevels () {
			const levels = Level.query().where('LevelType', 'Leadership').get()
			if (!levels?.length) { return leadershipLevelsDefaults }
			return levels
				.sort((a, b) => b.LevelRank - a.LevelRank)
				.map(({ LevelName }) => LevelName)
		},
		mAgent () {
			return this.agent || this.user.Agent
		},
		leadershipLevel () {
			return this.mAgent.LeadershipLevel
		},
		leadershipOrderRelativeToAO () {
			const AOIndex = this.leadershipLevels.indexOf('Agency Owner')
			const agentLLIndex = this.leadershipLevels.indexOf(this.leadershipLevel)
			if (agentLLIndex === AOIndex) { return 0 }
			return agentLLIndex > AOIndex ? -1 : 1
		},
		options () {
			if(this.hasRole(['SuperAdmin', 'Staff'])){
				return defaultOptions
			}
			if (this.leadershipOrderRelativeToAO === 0) {
				return defaultOptions.filter(({ value }) => value !== stats.totalAgency)
			}
			if (this.leadershipOrderRelativeToAO < 0) {
				return defaultOptions.filter(({ value }) => value !== stats.baseshop)
			}
			return defaultOptions
		},
		doShow () {
			return this.options.length > 1
		},
    },
	watch: {
		options: {
			deep: true,
			handler () {
				const exist = this.options.find(({ value }) => value === this.value)
				if (!exist) {
					this.mValue = stats.personal
				}
			},
		},
	},
	methods: {
		async getLevels () {
			this.loading = true
			try {
				await QuilityAPI.getAgentManagement_Levels()
			} catch (err) {
				console.error(err)
				// this.showError('Oops! There was a problem loading Leadership Levels.<br>', err.message)
			} finally {
				this.loading = false
			}
        },
	},
}
</script>
