<template>
	<v-list v-if="options.length" class="q-comprehensive-checkboxes-filter" :style="computedStyle">
		<v-list-item v-for="item in computedOptions" :key="item.key" dense>
			<div class="q-comprehensive-checkboxes-filter__item" @click="onClick(item.value)">
				<div class="q-comprehensive-checkboxes-filter__checkbox" :class="item.selected && 'item-checkbox--selected'">
					<v-icon v-if="item.selected" color="white">
						icon-checkmark-q
					</v-icon>
				</div>
				<div class="q-comprehensive-checkboxes-filter__label">
					<slot name="label">
						<p class="q-comprehensive-checkboxes-filter__item-label">{{ item.label }}</p>
					</slot>
				</div>
			</div>
		</v-list-item>
	</v-list>
	<div v-else class="q-comprehensive-checkboxes-filter__item-label mt-2">There is no filter options</div>
</template>

<script>
const ALL = '-All-'

export default {
    props: {
        value: { type: String, default: '' },
        options: { type: Array },
		color: { type: String, default: 'primary' },
		valueKey: { type: String, default: 'value' },
        textKey: { type: String, default: 'text' },
    },
    data () {
        return {
        }
    },
    computed: {
		mValue: {
			get () {
				if (!this.value) { return [] }
				return this.value.split(',').map(item => item.trim())
			},
			set (val) {
				if (!val?.length) {
					this.$emit('input', '')
				} else {
					this.$emit('input', val.join(','))
				}
			},
		},
		computedOptions () {
			if (!this.options) { return null }
			return this.options.map((item) => {
				if (typeof item === 'object') {
					return {
						key: item[this.valueKey],
						value: item[this.valueKey],
						label: item[this.textKey],
						selected: this.mValue.includes(item[this.valueKey]),
					}
				}
				return {
					key: item,
					value: item,
					label: item,
					selected: this.mValue.includes(item),
				}
			})
		},
		hasAllValue () {
			return this.computedOptions.some(({ value}) => value === ALL)
		},
		computedStyle () {
			const color = this.color === 'primary' ? '#46C3B2' : this.color
			return {
				'--comprehensive-checkboxes-color': color,
			}
		},
    },
    methods: {
		onClick (value) {
			if (this.mValue.includes(value)) {
				if (this.hasAllValue && value === ALL) { return }
				if (this.mValue.length === 1) {
					this.mValue = [ALL]
					return
				}
				const index = this.mValue.indexOf(value)
				this.mValue = [
					...this.mValue.slice(0, index),
					...this.mValue.slice(index + 1),
				]
			} else {
				if (value === ALL) {
					this.mValue = [ALL]
				} else if (this.mValue.includes(ALL)) {
					this.mValue = [value]
				} else {
					this.mValue = this.computedOptions.map((item) => {
						if (value === item.value) { return item.value }
						if (this.mValue.includes(item.value)) { return item.value }
						return false
					}).filter(Boolean)
				}
			}
		},
    },
}
</script>

<style lang="scss" scoped>
.q-comprehensive-checkboxes-filter {
	.v-list-item {
		padding: 0 0.5rem;
		min-height: 32px;
		border-radius: 6px;

		&::after {
			content: '';
			position: absolute;
			inset: 0;
			border-radius: inherit;
			background-color: rgba(0, 0, 0, 0.87);
			opacity: 0;
			pointer-events: none;
			transition: opacity 0.2s;
		}

		&:hover::after {
			opacity: 0.04;
		}
	}

	.q-comprehensive-checkboxes-filter__item {
		position: relative;
		display: flex;
		align-items: center;
		cursor: pointer;
		width: 100%;
		border-radius: 8px;
	}

	.q-comprehensive-checkboxes-filter__checkbox {
		$size: 20px;
		width: $size;
		height: $size;
		margin-right: 0.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 2px solid #D0D0D0;
		border-radius: 4px;

		.v-icon.v-icon {
			font-size: $size - 2px !important;
		}
	}

	.item-checkbox--selected {
		border: none;
		background-color: var(--comprehensive-checkboxes-color);
	}

	.q-comprehensive-checkboxes-filter__item-label {
		margin: 0;
		line-height: 1;
		font-size: 1rem;
		color: #2F2F2F;
	}
}
</style>
