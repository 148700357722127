<template>
    <v-container fluid>
        <v-row cols="12" class="d-flex justify-space-around ">
            <v-col cols="12">
                <p class="q-display-2 q_dark_gray--text">Assets</p>
                <p><strong>Cash, Investment and Retirement Accounts</strong></p>
            </v-col>
        </v-row>
        <!--Retirment assets -->
        <v-card cols="12" class="pa-5 ma-2 mt-7 d-flex flex-wrap">
            <v-col cols="12" class="flex-shrink-0">
                <p class="q-display-2 q_dark_gray--text">Retirement Assets
                    <v-btn @click="addAsset">Add Asset
                        <v-icon right>fas fa-plus-circle</v-icon>
                    </v-btn>
                </p>
            </v-col>
            <v-row>
                <v-card cols="12" flat class="flex-grow-1 px-4 q-alt-rows" min-width="100%" v-for="(asset, key) in lead.Assets" :key="key">
                    <v-row>
                        <v-col cols="12" md="2" class="d-flex justify-space-around align-top">
                            <v-card flat class="px-2" min-width="160px" color="transparent">
                                <v-select :items="AssetTypes" v-model="asset.AssetType"></v-select>
                            </v-card>
                        </v-col>
                        <v-col cols="11" md="9" class="d-flex flex-wrap">
                            <v-card flat class="px-5" color="transparent">
                                <v-text-field v-model="asset.current_value" prepend-icon="fas fa-file-invoice-dollar" label="current value" @blur="updateAssetFormat(key)" @focus="updateAssetFormat(key)"></v-text-field>
                            </v-card>
                            <v-card flat class="px-5" color="transparent">
                                <v-text-field v-model="asset.provider" prepend-icon="fas fa-edit" label="provider"></v-text-field>
                            </v-card>
                            <v-card flat class="px-5" color="transparent">
                                <v-text-field v-model="asset.purpose" prepend-icon="fas fa-umbrella" label="purpose"></v-text-field>
                            </v-card>
                            <v-card flat class="px-5" color="transparent">
                                <v-text-field v-model="asset.contribution" prepend-icon="fas fa-dollar-sign" label="contribution" @blur="updateAssetFormat(key)" @focus="updateAssetFormat(key)"></v-text-field>
                            </v-card>
                            <v-card flat class="px-5" color="transparent">
                                <div>
                                    <div style="display:inline-block" class="mr-5">
                                        <p>Current Employer?</p>
                                    </div>
                                    <div style="display:inline-block">
                                        <v-radio-group v-model="asset.current_er" row>
                                            <v-radio label="Yes" value="1"></v-radio>
                                            <v-radio label="No" value="0"></v-radio>
                                        </v-radio-group>
                                    </div>
                                </div>
                            </v-card>
                        </v-col>
                        <v-col cols="1" md="1" class="d-flex flex-wrap">
                            <v-btn fab color="red" @click="deleteAsset(key)">
                                <v-icon>fas fa-times-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            </v-row>
        </v-card>
        <!--/retirment assets -->
        <!--Debt -->
        <v-card cols="12" class="pa-5 ma-2 mt-7 d-flex flex-wrap">
            <v-col cols="12" class="flex-shrink-0">
                <p class="q-display-2 q_dark_gray--text">Debt Information
                    <v-btn @click="addDebt">Add Debt
                        <v-icon right>fas fa-plus-circle</v-icon>
                    </v-btn>
                </p>
            </v-col>
            <v-row>
                <v-card cols="12" flat class="flex-grow-1 px-4 q-alt-rows" min-width="100%" v-for="(debt, key) in lead.Debts" :key="key">
                    <v-row>
                        <v-col cols="12" md="2" class="d-flex justify-space-around align-center">
                            <v-card flat class="px-2" min-width="160px" color="transparent">
                                <v-select :items="DebtTypes" v-model="debt.DebtType"></v-select>
                            </v-card>
                        </v-col>
                        <v-col cols="11" md="9" class="d-flex flex-wrap">
                            <v-card flat class=" px-5" color="transparent">
                                <v-text-field v-model="debt.payoff_amt" prepend-icon="fas fa-dollar-sign" label="payoff amnt" @blur="updateDebtFormat(key)" @focus="updateDebtFormat(key)"></v-text-field>
                            </v-card>
                        </v-col>
                        <v-col cols="1" md="1" class="d-flex flex-wrap">
                            <v-btn fab color="red" @click="deleteDebt(key)">
                                <v-icon>fas fa-times-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            </v-row>
        </v-card>
        <!--/debt-->
        <v-card cols="12" class="pa-5 ma-2 mt-7 d-flex flex-wrap">
            <v-col cols="12" class="flex-shrink-0">
                <p class="title">Funds quickly accesable</p>
            </v-col>
            <v-card flat cols="12" sm="4">
                <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-title>Bank and Brokerage accounts, CD's, etc </v-list-item-title>
                        <v-list-item-subtitle>
                            <v-text-field v-model="lead.Funds" prepend-icon="fas fa-dollar-sign" label="sum all"></v-text-field>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-card>
        </v-card>
    </v-container>
</template>
<script>
import { formatField } from '@/components/utils/FormatNeedsAnalysisFields'
export default {
    data: () => {
        return {
            AssetTypes: [
                "401k", "403b", "457", "IRA", "Pension", "Annuity", "CD", "Savings", "Investments"
            ],
            DebtTypes: [
                "Credit Card", "Student Loan", "Personal", "Car Loan"
            ]
        }
    },
    mounted: function() {

    },
    methods: {
        addAsset: function() {
            this.lead.Assets.push({
                AssetType: "401k",
                current_value: 0,
                provider: null,
                purpose: null,
                contribution: null,
                current_er: false
            })
        },
        deleteAsset: function(key) {
            this.lead.Assets.splice(key, 1)
        },
        addDebt: function() {
            this.lead.Debts.push({
                DebtType: "Credit Card",
                payoff_amt: 0,
            })
        },
        deleteDebt: function(key) {
            this.lead.Debts.splice(key, 1)
        },
        updateDebtFormat: function(key) {
            this.lead.Debts[key].payoff_amt = formatField('payoff_amt',this.lead.Debts[key])
        },
        updateAssetFormat: function(key){
           this.lead.Assets[key].current_value = formatField('current_value',this.lead.Assets[key])
           this.lead.Assets[key].contribution = formatField('contribution',this.lead.Assets[key])
        }
    },
    props: ['lead'],
    watch: {
        'lead': function(newV) {

        }
    }
}

</script>
