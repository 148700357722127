<template>
	<span class="q-highlighted-search-string" :style="computedStyle">
		<span v-if="!items.length">{{ item }}</span>
		<template v-else>
			<template v-for="(item, idx) in items">
				<template v-if="needSpace(idx)">&nbsp;</template>
				<span :key="idx" :class="item.highlighted && 'highlighted-item'">
					{{ item.value }}
				</span>
			</template>
		</template>
	</span>
</template>

<script>
export default {
	props: {
		item: { type: String, default: '' },
		search: { type: String, default: '' },
		minSearchLength: { type: Number, default: 2 },
		color: { type: String, default: '#FFE195' },
	},
    computed: {
		normalizedSearch () {
			if (!this.search || this.search.length < this.minSearchLength) { return '' }
			return this.search.trim()
		},
        hasSearch () {
			return this.item && this.normalizedSearch
		},
		searchRegex () {
			const escapedSearch = this.normalizedSearch.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
			return new RegExp(escapedSearch, 'i')
		},
		items () {
			if (!this.hasSearch) { return [] }
			const searchStartInd = this.item.search(this.searchRegex)
			if (searchStartInd === -1) { return [] }
			const searchEndInd = searchStartInd + this.normalizedSearch.length
			return [
				{ value: this.item.slice(0, searchStartInd) },
				{ value: this.item.slice(searchStartInd, searchEndInd), highlighted: true },
				{ value: this.item.slice(searchEndInd) },
			].filter(({ value }) => value)
		},
		computedStyle () {
			return {
				'--highlighted-search-string': this.color,
			}
		},
    },
	methods: {
		needSpace (idx) {
			if (idx === 0) { return false }
			const { highlighted } = this.items[idx]
			if (highlighted && this.items[idx - 1].value.endsWith(' ')) { return true }
			if (!highlighted && this.items[idx].value.startsWith(' ')) { return true }
			return false
		},
	},
}
</script>

<style lang="scss" scoped>
.q-highlighted-search-string {
	display: inline-flex;
	margin: 0;
	padding: 0;
	line-height: 1.3;

	.highlighted-item {
		background-color: var(--highlighted-search-string);
	}
}
</style>
