<template>
    <v-container fluid class="q-sticky-buffer policy-finder">
        <!-- /help and wayfinding -->
        <v-card cols="12" class="mx-3" elevation="3" height="100%">
            <v-row class="mx-3 mb-0 pa-0 q_new_b_1">
                <v-col cols="8" class="pl-1 py-0">
                    <p class="q-display-3 pl-5 py-2 white--text">
                        Policy Finder
                        <span v-if="lead">
                            for <em>{{ lead.Contact }}</em>
                        </span>
                        <v-btn @click="startNewClient()" class="ml-6">New Client
                            <v-icon right>fas fa-plus-circle</v-icon>
                        </v-btn>
                    </p>
                </v-col>
                <q-search-needs-analysis-lead base-url="/new_business/workflow/::LEADID::/policy-finder"></q-search-needs-analysis-lead>
                <v-col cols="12" class="pa-0 ma-0">
                    <div class="text-center">
                        <v-progress-linear height="12" indeterminate v-if="loading"></v-progress-linear>
                    </div>
                </v-col>
            </v-row>
            <div v-if="lead && false">
                <client-info :lead.sync="lead"></client-info>
            </div>
            <v-form ref="form" v-model="form_valid">
                <v-row class=" mx-5 px-3 pt-5" justify="start" align="stretch">
                    <v-col cols="12" sm="4" class="">
                        <v-select required :rules="[rules.required]" v-model="policy_params.state" :items="states" item-text="state" return-object label="State"></v-select>
                    </v-col>
                </v-row>
                <v-row class="mx-5 px-3 grey lighten-4" justify="start" align="stretch">
                    <v-col cols="3">
                        <div style="display:inline-block; max-width:30%; height:40px;"> Height:</div>
                        <div style="display:inline-block;width:80px;  max-width:30%;height:40px; margin-left:2em;">
                            <v-select :rules="[rules.required]" required v-model="policy_params.height_feet" :items="feet" label="feet"></v-select>
                        </div>
                        <div style="display:inline-block; width:80px; max-width:30%;height:40px; margin-left:2em;">
                            <v-select :rules="[rules.required]" required v-model="policy_params.height_inches" :items="inches" label="inches"></v-select>
                        </div>
                    </v-col>
                    <v-col cols="3">
                        <div style="display:inline-block; max-width:30%; height:40px;">Weight:</div>
                        <div style="display:inline-block;width:80px;  max-width:30%;height:40px; margin-left:2em;">
                            <v-text-field :rules="[rules.required, rules.numeric]" required v-model="policy_params.weight" label="lbs" single-line></v-text-field>
                        </div>
                    </v-col>
                    <v-col cols="3">
                        <div style="display:inline-block; max-width:30%; height:40px;">Age:</div>
                        <div style="display:inline-block;width:80px;  max-width:30%;height:40px; margin-left:2em;">
                            <v-text-field :rules="[rules.required, rules.numeric]" required v-model="policy_params.age" label="years" single-line></v-text-field>
                        </div>
                    </v-col>
                </v-row>
                <v-row class="mx-5 px-3 pb-3" justify="start" align="stretch">
                    <v-col cols="2">
                        <div class="pt-5" style="display:inline-block; height:40px;">
                            Tobacco Use:
                        </div>
                    </v-col>
                    <v-col cols="6">
                        <div style="display:inline-block; height:40px;">
                            <v-radio-group :rules="[rules.required]" required v-model="policy_params.tobacco_use.id" row>
                                <v-radio v-for="(t, k) in tobacco" :key="k" :label="t.label" :value="t.id"></v-radio>
                            </v-radio-group>
                        </div>
                    </v-col>
                </v-row>
                <v-row class="mx-5 grey px-3 lighten-4" justify="start" align="stretch">
                    <v-col cols="2">
                        <div class="pt-5" style="display:inline-block; height:40px;">
                            Optional Filters:
                        </div>
                    </v-col>
                    <v-col cols="12" sm="9" class="denser">
                        <v-row>
                            <v-col cols="12" sm="3">
                                <p class="title">Product</p>
                                <v-checkbox dense v-for="(p, k) in purpose" :key="k" v-model="policy_params.purpose" :value="p" :label="p.label" :value-comparator="compareIds"></v-checkbox>
                            </v-col>
                            <v-col cols=" 12" sm="3" class="">
                                <p class="title">Policy Type</p>
                                <v-checkbox dense v-for="(p, k) in policy_type" :key="k" v-model="policy_params.policy_type" :value="p" :label="p.label" :value-comparator="compareIds"></v-checkbox>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <p class="title">Riders</p>
                                <v-checkbox dense v-for="(p, k) in riders" :key="k" v-model="policy_params.riders" :value="p" :label="p.label" :value-comparator="compareIds"></v-checkbox>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <p class="title">Underwriting</p>
                                <v-checkbox dense v-for="(p, k) in underwriting" :key="k" v-model="policy_params.underwriting" :value="p" :label="p.label" :value-comparator="compareIds"></v-checkbox>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row class="mx-5 px-3 " justify="start" align="stretch">
                    <v-col cols="3">
                        <div class="pt-5" style="display:inline-block; height:40px;">
                            Health Status:<br /><span class="caption">check all that apply</span>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="9" class="denser">
                        <v-row>
                            <v-col cols="12" sm="3">
                                <v-checkbox dense v-model="health_status" label="Cancer" value="Cancer"></v-checkbox>
                                <v-checkbox dense v-model="health_status" label="Disabled (under age 65)" value="Disabled"></v-checkbox>
                                <v-checkbox dense v-model="health_status" label="Kidney" value="Kidney"></v-checkbox>
                                <v-checkbox dense v-model="health_status" label="Lung" value="Lung"></v-checkbox>
                            </v-col>
                            <v-col cols=" 12" sm="3" class="">
                                <v-checkbox dense v-model="health_status" label="Diabetes" value="Diabetes"></v-checkbox>
                                <v-checkbox dense v-model="health_status" label="Immune & Neurological" value="Immune & Neurological"></v-checkbox>
                                <v-checkbox dense v-model="health_status" label="Heart / Circulatory" value="Heart / Circulatory"></v-checkbox>
                                <v-checkbox dense v-model="health_status" label="Mental / Nervous Disorders" value="Mental / Nervous Disorders"></v-checkbox>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <v-checkbox dense v-model="health_status" label="Digestive" value="Digestive"></v-checkbox>
                                <v-checkbox dense v-model="health_status" label="Joint & Muscle" value="Joint & Muscle"></v-checkbox>
                                <v-checkbox dense v-model="health_status" label="Liver" value="Liver"></v-checkbox>
                                <v-checkbox dense v-model="health_status" label="Other" value="Other"></v-checkbox>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row class="ma-5 px-3" justify="start" style="border-top:#cccccc thin solid;">
                    <div class="my-2 py-5">
                        <v-btn x-large color="q_new_b_1" dark @click="submitPolicySearch">Search Policy Options</v-btn>
                        <v-overlay :value="overlay">
                            <v-progress-circular indeterminate size="64"></v-progress-circular>
                        </v-overlay>
                    </div>
                </v-row>
            </v-form>
        </v-card>
        <v-card id="results" class="pa-5" cols="12" v-if="show_results">
            <div v-for="(i) in 10">
                <v-row justify="start" align="stretch" v-if="getTier(i).length > 0">
                    <v-col cols="12">
                        <div class="ml-8 pl-4" style="border-left:#3F51B5 thick solid;">
                            <q-policy-finder-results-table :title="'Tier ' + i + ' Products'" :rows="3" :results="getTier(i)">
                            </q-policy-finder-results-table>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </v-card>
    </v-container>
</template>
<script>
import VirtualMentorAPI from '@/store/API/VirtualMentorAPI'
import QPolicyFinderResultsTable from '@/components/datatables/QPolicyFinderResultsTable.vue';
import Lead from '@/store/Models/Lead'
import options from '@/store/VirtualMentor.json'
import QSearchNeedsAnalysisLead from '@/components/utils/QSearchNeedsAnalysisLead.vue'
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { debounce } from 'vue-debounce'
import NeedsAnalysisLead from '@/store/Models/NeedsAnalysisLead.js'
import ClientInfo from './NeedsAnalysis/ClientInfo.vue';
//http://localhost:8080/new_business/workflow/policy-finder
export default {
    data: () => ({
        rules: {
            'required': v => !!v || 'This field is required',
            'numeric': v => !isNaN(v) || 'This must be a number'
        },
        lead_id: null,
        lead: null,
        feet: ['1', '2', '3', '4', '5', '6', '7', ],
        inches: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', ],
        overlay: false,
        show_results: false,
        sheet: false,
        health_status: [],
        form_valid: false,
        results: [],
        policy_params: {
            state: null,
            height_inches: null,
            height_feet: null,
            weight: null,
            age: null,
            tobacco_use: {},
            policy_type: [],
            purpose: [],
            riders: [],
            underwriting: []
        },

    }),
    mounted: function() {
        this.reset();
    },
    computed: {
        loading: function() {
            return this.$store.state.entities.needs_analysis_lead.loading
        },
        'states': function() {
            return options.states;
        },
        'purpose': function() {
            return options.purpose;
        },
        'policy_type': function() {
            return options.policy_type;
        },
        'riders': function() {
            return options.riders;
        },
        'underwriting': function() {
            return options.underwriting;
        },
        'tobacco': function() {
            return options.tobacco;
        },
        /*lead: function() {
            if (Lead.find(this.lead_id) !== null) {
                this.$emit('lead', this.lead_id);
                return Lead.find(this.lead_id);
            }
            return {};
        },*/
    },
    methods: {
        getTier: function(tier) {
            var r = [];
            for (var i = 0; i < this.results.length; i++) {
                if (this.results[i].tiers == tier) {
                    r.push(this.results[i]);
                }
            }
            return r;
        },
        ShowOverlay: function() {

            var g = this
            setTimeout(
                function() {

                    g.$nextTick(function() {
                        g.$vuetify.goTo("#results")
                    });
                },
                1000
            )
        },
        compareIds: function(a, b) {
            if (a.id == b.id) {
                return true;
            }
            return false
        },
        submitPolicySearch: function() {
			if (this.isDemoMode) {
				this.showError("You can not do it in Demo Mode!")
				return
			}

            var g = this
            this.$refs.form.validate()
            if (this.form_valid) {
                this.overlay = !this.overlay;
                VirtualMentorAPI.submitPolicySearch(this.policy_params).then(function(results) {
                    g.overlay = false
                    g.show_results = true
                    if (results.error == 0) {
                        g.results = results.result.advance_search_insurance_data
                        g.$nextTick(function() {
                            g.$vuetify.goTo("#results")
                        })
                    } else {
                        g.showError("Error" + result.message)
                        g.$vuetify.goTo(1)
                    }
                }).catch(function(err) {
                    console.log("ERROR", err.message)
                    g.showError("Error" + err)
                })
            } else {
                g.showError('Please fill out the required fields.')
                g.$vuetify.goTo(1)
            }
        },
        reset: function() {
            var g = this;
            //Needs Analysis Lead
            if (typeof id != 'undefined') {
                this.lead_id = id
            } else {
                this.lead_id = this.$route.params.lead_id;
            }
            if (this.lead_id) {
                var l = NeedsAnalysisLead.find(this.lead_id);
                if (l != null) {
                    g.$set(g, 'lead', l)
                    g.$nextTick(function() {
                        g.updatePolicyParams();
                    });
                    return
                }
                //load lead from API or 404 that shit.
                QuilityAPI.getMyNeedsAnalysisLead(this.lead_id).then(
                    function(lead) {
                        if (lead) {
                            g.$set(g, 'lead', lead)
                            g.$nextTick(function() {
                                g.updatePolicyParams();
                            });
                        } else {
                            g.$router.push('/404');
                        }
                    },
                    function(error) {
                        console.log(error);
                        g.showError(error.msg);
                    })
            } else {
                NeedsAnalysisLead.new().then((item) => {
                    g.$emit('lead', item.$id);
                    g.lead = item;
                });
            }
            this.sheet = false

            //VM Query reset stuff
            this.show_results = false
            this.policy_params = {
                state: {},
                height_inches: null,
                height_feet: null,
                weight: null,
                age: null,
                tobacco_use: {},
                policy_type: [],
                purpose: [],
                riders: [],
                underwriting: []
            }
            VirtualMentorAPI.getVMToken().then(function(token) {
                g.VM_token = token;
            })
        },
        updatePolicyParams: function() {
            console.log(this.lead)

        }
    },
    watch: {
        '$route.params.lead_id': function(newV, oldV) {
            if (typeof newV != 'undefined' && this.lead_id != newV) {
                this.reset();
            }
        },
        'policy_params.tobacco_use.id': function(newV, oldV) {
            for (var i = 0; i < options.tobacco.length; i++) {
                if (newV == options.tobacco[i].id) {
                    this.policy_params.tobacco_use.label = options.tobacco[i].label;
                    break;
                }
            }
        }

    },
    components: {
        QPolicyFinderResultsTable,
        QSearchNeedsAnalysisLead,
        ClientInfo
    }
}

</script>
