<template>
    <QDynamicSubmenu
        :base-url="baseUrl"
        :default-title="title"
        :default-buttons="buttons"
    />
</template>

<script>
//table data + search
import QDynamicSubmenu from '@/components/navigation/Submenus/base/QDynamicSubmenu.vue'

export default {
    name: "QNewBusinessSubmenu",
    data() {
        return {
            title: "New Business",
            baseUrl: '/new-business',
            buttons: [
                {
                    label: "New Business",
                    anchor: "#News",
                    icon: "fas fa-arrow-alt-circle-down",
                }
            ],
        }
    },
    components: {
        QDynamicSubmenu,
    },
}
</script>

<style></style>
