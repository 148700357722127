<template>
    <v-container fluid>
        <v-row cols="12" class="d-flex justify-space-around ">
            <v-col cols="12">
                <p class="q-display-2 q_dark_gray--text">Assets</p>
            </v-col>
        </v-row>
        <!--Mortgage assets -->
        <v-card cols="12" class="pa-5 ma-2 mt-7" elevation="0">
            <p class="q-title q_dark_gray--text">Mortgage Information
            </p>
            <v-row>
                <v-card cols="12" flat class="flex-grow-1 px-4 q-alt-rows" min-width="100%" v-for="(mortgage, key) in assets.Mortgage" :key="key">
                    <v-row>
                        <v-col class="d-flex flex-wrap">
                            <!--
                            <v-card flat class="px-3" color="transparent">
                                <v-text-field v-model="mortgage.lender" prepend-icon="" label="Lender"></v-text-field>
                            </v-card>
                            <v-card flat class="px-3" color="transparent">
                                <v-text-field v-model="mortgage.interest" prepend-icon="fas fa-percentage" label="Interest %"></v-text-field>
                            </v-card>
                            -->
                            <v-card flat class="px-3" color="transparent">
                                <v-text-field v-model="mortgage.term" prepend-icon="" label="Term"></v-text-field>
                            </v-card>
                            <v-card flat class="px-3" color="transparent">
                                <v-text-field v-model="mortgage.loanAmount" prepend-icon="fas fa-dollar-sign" label="Loan Amount"></v-text-field>
                            </v-card>
                            <v-card flat class="px-3" color="transparent">
                                <v-text-field v-model="mortgage.payment" prepend-icon="fas fa-dollar-sign" label="Payment"></v-text-field>
                            </v-card>
                            <v-card flat class="px-3" color="transparent">
                                <v-text-field v-model="mortgage.acceleratedAmount" prepend-icon="fas fa-dollar-sign" label="Overpayment"></v-text-field>
                            </v-card>
                            <v-card flat class="px-3" color="transparent">
                                <v-text-field v-model="mortgage.estimateEquity" prepend-icon="fas fa-dollar-sign" label="Estimated Equity"></v-text-field>
                            </v-card>
                        </v-col>
                        <v-col cols="1" md="1" class="d-flex flex-wrap">
                            <v-btn fab icon color="red" @click="deleteMortgage(key)">
                                <v-icon>fas fa-times-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            </v-row>
            <v-btn @click="addMortgage" text depressed color="primary" class="ma-0 pa-0">Add a mortgage
                <v-icon right>fas fa-plus-circle</v-icon>
            </v-btn>
        </v-card>
        <!--/mortgage assets -->
        <v-divider />
        <!--Insurance Policies -->
        <v-card cols="12" class="pa-5 ma-2 mt-7 " elevation="0">
            <p class="q-title q_dark_gray--text" style="display:block;">Insurance Information</p>
            <v-row>
                <v-card cols="11" flat class="flex-grow-1 px-4 q-alt-rows" v-for="(insurance, key) in assets.Insurance" :key="key">
                    <v-row class="mt-5">
                        <v-col class="d-flex flex-wrap">
                            <v-card flat class="px-3" color="transparent">
                                <v-text-field v-model="insurance.type" prepend-icon="" label="Type"></v-text-field>
                            </v-card>
                            <v-card flat class="px-3" color="transparent">
                                <v-text-field v-model="insurance.faceAmount" prepend-icon="fas fa-dollar-sign" label="Face Amount"></v-text-field>
                            </v-card>
                            <v-card flat class="px-3" color="transparent">
                                <v-text-field v-model="insurance.purpose" label="Purpose"></v-text-field>
                            </v-card>
                            <v-card flat class="px-3" color="transparent">
                                <v-text-field v-model="insurance.premium" prepend-icon="fas fa-dollar-sign" label="Premium"></v-text-field>
                            </v-card>
                            <v-card flat class="px-3" color="transparent">
                                <v-text-field v-model="insurance.cashValue" prepend-icon="fas fa-dollar-sign" label="Cash Value"></v-text-field>
                            </v-card>
                            <v-card flat class="px-3" color="transparent">
                                <v-text-field v-model="insurance.carrier" prepend-icon="" label="Carrier"></v-text-field>
                            </v-card>
                            <v-card flat class="px-3" color="transparent">
                                <v-text-field v-model="insurance.expirationDate" prepend-icon="" label="Expiration Date"></v-text-field>
                            </v-card>
                        </v-col>
                        <v-col cols="1" md="1" class="d-flex flex-wrap">
                            <v-btn fab icon color="red" @click="deleteInsurance(key)">
                                <v-icon>fas fa-times-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            </v-row>
            <v-btn @click="addInsurance" text depressed color="primary" class="ma-0 pa-0">Add a policy
                <v-icon right>fas fa-plus-circle</v-icon>
            </v-btn>
        </v-card>
        <!--/Insurance Policies-->
        <v-divider />
        <!--Additional Assets -->
        <v-card cols="12" class="pa-5 ma-2 mt-7 " elevation="0">
            <p class="q-title q_dark_gray--text">Additional Assets
            </p>
            <div>
                <p class="body-2 q_dark_gray--text">Total Assets: {{ TotalAdditionalAssets }} </p>
            </div>
            <v-row>
                <v-card cols="12" flat class="flex-grow-1 px-4 q-alt-rows" min-width="100%" v-for="(asset, key) in assets.Assets" :key="key">
                    <v-row class="d">
                        <v-col class="d-flex align-end flex-wrap">
                            <v-card flat class="px-3" color="transparent">
                                <div class="caption pt-0 mt-0" style="position:relative; top:0;">Type of asset</div>
                                <v-select :items="AssetTypes" v-model="asset.AssetType"></v-select>
                            </v-card>
                            <v-card flat class="px-3" color="transparent">
                                <v-text-field v-model="asset.current_value" prepend-icon="fas fa-dollar-sign" label="Current Value"></v-text-field>
                            </v-card>
                            <v-card flat class="px-3" color="transparent">
                                <v-container fluid>
                                    <div class="caption pt-0 mt-0" style="position:relative; top:0;">Currently Contributing?</div>
                                    <v-radio-group v-model="asset.contribution" row>
                                        <v-radio label="Yes" color="primary" value="yes"></v-radio>
                                        <v-radio label="No" color="primary" value="No"></v-radio>
                                    </v-radio-group>
                                </v-container>
                            </v-card>
                            <v-card flat class="px-3" color="transparent">
                                <v-text-field v-model="asset.employeecontribution" prepend-icon="fas fa-dollar-sign" label="Employee Contribution"></v-text-field>
                            </v-card>
                            <v-card flat class="px-3" color="transparent">
                                <v-text-field v-model="asset.employercontribution" prepend-icon="fas fa-dollar-sign" label="Employer Contribution"></v-text-field>
                            </v-card>
                            <v-card flat class="px-3" color="transparent">
                                <v-text-field v-model="asset.purpose" label="Purpose"></v-text-field>
                            </v-card>
                        </v-col>
                        <v-col cols="1" md="1" class="d-flex flex-wrap">
                            <v-btn fab icon color="red" @click="deleteAsset(key)">
                                <v-icon>fas fa-times-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            </v-row>
            <v-row class="d-flex">
                <v-btn @click="addAsset" text depressed color="primary" class="ma-0 pa-0">Add an asset
                    <v-icon right>fas fa-plus-circle</v-icon>
                </v-btn>
                <v-spacer />
            </v-row>
        </v-card>
        <!--/Additional Assets -->
    </v-container>
</template>
<script>
import { formatField } from '@/components/utils/FormatNeedsAnalysisFields'
export default {
    data: () => {
        return {
            AssetTypes: [
                "401k", "403b", "457", "Annuity", "CD", "Investments", "IRA", "Pension", "Savings", "Other"
            ],
            DebtTypes: [
                "Car Loan", "Credit Card", "Personal", "Student Loan"
            ]
        }
    },
    mounted: function() {

    },
    methods: {
        addAsset: function() {
            this.assets.Assets.push({
                AssetType: "401k",
                current_value: null,
                provider: null,
                purpose: null,
                contribution: null,
                employeecontribution: null,
                employercontribution: null,
                current_er: false
            })
        },
        deleteAsset: function(key) {
            this.assets.Assets.splice(key, 1)
        },
        addMortgage: function() {
            this.assets.Mortgage.push({
                AssetType: "Mortgage",
                lender: null,
                interest: null,
                term: null,
                loanAmount: null,
                payment: null,
                acceleratedAmount: null,
                estimateEquity: null

            })
        },
        deleteMortgage: function(key) {
            this.assets.Mortgage.splice(key, 1)
        },
        addInsurance: function() {
            this.assets.Insurance.push({
                type: "Insurance",
                faceAmount: null,
                purpose: null,
                premium: null,
                cashValue: null,
                carrier: null,
                expirationDate: null,
            })
        },
        deleteInsurance: function(key) {
            this.assets.Insurance.splice(key, 1)
        },
    },
    computed: {
        TotalAdditionalAssets: function() {
            let expense = 0
            const len = this.assets.Assets.length
            const reducer = (accumulator, currentAmount) => {
                let commaFreeValue

                if (typeof currentAmount.current_value == "string") {
                    commaFreeValue = currentAmount.current_value.replace(/,/g, '')
                } else {
                    commaFreeValue = currentAmount
                }

                return accumulator + parseFloat(commaFreeValue)
            }

            if (len > 0) {
                expense = this.assets.Assets.reduce(reducer, 0)
            }

            return len > 0 ? formatField(null, expense, true) : 0
        },
    },
    props: ['assets'],
    watch: {
        // 'assets': function(newV) {

        // }
    }
}

</script>
