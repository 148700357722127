<template>
    <v-container fluid class="grey lighten-5 px-7">
        <v-row justify="space-between" align="start">
            <v-col cols="12">
                <h2>Quoting</h2>
                <ul>
                    <li>This currently happens on the individual carrier sites. </li>
                    <li>Surefy will be initially be able to quote American Amicable and hopefully more in the future.
                    </li>
                </ul>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
//table data + search
export default {
    data() {
        return {

        }
    },
    components: {

    }
}
</script>