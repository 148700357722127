<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-new-business-submenu></q-new-business-submenu>
        <!-- help and wayfinding -->
        <!-- /help and wayfinding -->
        <v-row style="height:100px;"></v-row>
        <v-row class="mx-3 mb-0 pt-3 q_new_b_1">
            <v-col id="v-na-1" cols="8" class="pl-1 py-0">
                <p class="q-display-3 pl-5 py-2 white--text">
                    Financial Information Form
                </p>
            </v-col>
            <!-- <q-search-needs-analysis-lead></q-search-needs-analysis-lead> -->
            <v-col cols="12" class="pa-0 ma-0">
                <div class="text-center">
                    <v-progress-linear height="12" indeterminate v-if="loading"></v-progress-linear>
                </div>
            </v-col>
        </v-row>
        <v-stepper v-model="step" id="stepper" class="mx-3 mt-0">
            <v-stepper-header>
                <v-stepper-step color="q_new_b_3" :complete="step > 1" step="1" @click="nextStep(1)" class="pointer">Client Contact</v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step color="q_new_b_3" :complete="step > 2" step="2" @click="nextStep(2)" class="pointer">Assets</v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step color="q_new_b_3" :complete="step > 3" step="3" @click="nextStep(3)" class="pointer">Income/Expenses</v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step color="q_new_b_3" :complete="step > 4" step="4" @click="nextStep(4)" class="pointer">Debt</v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step color="q_new_b_3" :complete="step > 5" step="5" @click="nextStep(5)" class="pointer">Results</v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
                <v-stepper-content step="1">
                    <v-col cols="12">
                        <contact :clientContact.sync="clientContact"></contact>
                        <v-row cols="12" class="my-5 px-4 d-flex justify-space-between">
                            <v-spacer></v-spacer>
                            <v-btn dark color="q_new_b_1" @click="nextStep(2)">
                                next<v-icon class="pl-3">fas fa-chevron-right</v-icon>
                            </v-btn>
                        </v-row>
                    </v-col>
                </v-stepper-content>
                <v-stepper-content step="2">
                    <v-col cols="12">
                        <assets :assets.sync="assets"></assets>
                        <v-row cols="12" class="my-5 px-4 d-flex justify-space-between">
                            <v-btn outlined color="q_new_b_1" @click="nextStep(1)">
                                <v-icon class="pr-3">fas fa-chevron-left</v-icon>back
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn dark color="q_new_b_1" @click="nextStep(3)">
                                next<v-icon class="pl-3">fas fa-chevron-right</v-icon>
                            </v-btn>
                        </v-row>
                    </v-col>
                </v-stepper-content>
                <v-stepper-content step="3">
                    <v-col cols="12">
                        <expenses :expenses.sync="expenses"></expenses>
                        <v-row cols="12" class="my-5 px-4 d-flex justify-space-between">
                            <v-btn outlined color="q_new_b_1" @click="nextStep(2)">
                                <v-icon class="pr-3">fas fa-chevron-left</v-icon>back
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn dark color="q_new_b_1" @click="nextStep(4)">
                                next<v-icon class="pl-3">fas fa-chevron-right</v-icon>
                            </v-btn>
                        </v-row>
                    </v-col>
                </v-stepper-content>
                <v-stepper-content step="4">
                    <v-col cols="12">
                        <debt :debt.sync="debt"></debt>
                        <v-row cols="12" class="my-5 px-4 d-flex justify-space-between">
                            <v-btn outlined color="q_new_b_1" @click="nextStep(3)">
                                <v-icon class="pr-3">fas fa-chevron-left</v-icon>back
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn dark color="q_new_b_1" @click="nextStep(5)">
                                next<v-icon class="pl-3">fas fa-chevron-right</v-icon>
                            </v-btn>
                        </v-row>
                    </v-col>
                </v-stepper-content>
                <v-stepper-content step="5">
                    <v-col cols="12">
                        <results :results.sync="results" :assets.sync="assets" :debt.sync="debt" :emailStatus.sync="emailStatus" @send-email="sendEmail"></results>
                        <v-row cols="12" class="my-5 d-flex justify-space-between">
                            <v-btn outlined color="q_new_b_1" @click="nextStep(4)">
                                <v-icon class="pr-3">fas fa-chevron-left</v-icon>back
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn dark color="q_new_b_1" depressed @click="resetFIF">
                                Reset<v-icon class="pl-3">fas fa-sync-alt</v-icon>
                            </v-btn>
                        </v-row>
                    </v-col>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
    </v-container>
</template>
<script>
import QNewBusinessSubmenu from '@/components/navigation/Submenus/QNewBusinessSubmenu.vue';
import Contact from './FIF/FIFClientContact.vue';
import Assets from './FIF/FIFAssets.vue';
import Expenses from './FIF/FIFExpenses.vue';
import Debt from './FIF/FIFDebt.vue';
import Results from './FIF/FIFResults.vue';
import QuilityAPI from '@/store/API/QuilityAPI.js'

export default {
    data() {
        return {
            AdditionalAssets: 0, // get's set when results email is sent out
            step: 1,
            sheet: false,
            assets: {
                Mortgage: [],
                Insurance: [],
                Assets: [],
            },
            clientContact: {
                InsuredName: null,
                Email: null,
                Phone: null,
                Street1: null,
                Street2: null,
                City: null,
                States: null,
                Zip: null,
            },
            debt: {
                CreditCardDebt: [],
                StudentLoanDebt: [],
                PersonalDebt: [],
                CarLoanDebt: [],
            },
            expenses: {
                Assets: [],
                ClientWages: null,
                ClientSocialSecurity: null,
                ClientRetirement: null,
                SpouseWages: null,
                SpouseSocialSecurityIncome: null,
                SpouseRetirement: null,
            },
            results: {
                Q1: null,
                Q2: null,
                Q3: null,
                QRSIncomeGap: null,
                QRSProjectedRetirementYears: null,
                QRSRetirementDecrease: null,
                QRSOptions: {
                    Q1: {
                        Quesition: "Has your current advisor shown you what your income gap will be when you retire?",
                        Answers: [{
                                Label: "Yes",
                                NextQuestion: "Q2"
                            },
                            {
                                Label: "No",
                                NextQuestion: "Q3"
                            },
                        ],
                        MainQuestion: true
                    },
                    Q2: {
                        Quesition: "Okay great, what is it approximately?",
                        Answers: [{
                            FieldLabel: "Enter Dollar Amount",
                            ButtonLabel: "Next",
                            NextQuestion: "Q4",
                            Field: "QRSIncomeGap",
                            Icon: "fas fa-dollar-sign",
                        }, {

                            Label: "Can't provide an exact amount",
                            NextQuestion: "Q3"
                        }, ],
                        MainQuestion: false
                    },
                    Q3: {
                        Quesition: "Is it better to find out what that “gap” is now or when you retire?",
                        Answers: [{
                                Label: "Now",
                                NextQuestion: "Q100" // denotes the Reset script
                            },
                            {
                                Label: "Retire",
                                NextQuestion: "Q4"
                            },
                        ],
                        MainQuestion: false
                    },
                    Q4: {
                        Quesition: "Do you know how long your retirement assets are projected to last?",
                        Answers: [{
                                Label: "Yes",
                                NextQuestion: "Q5"
                            },
                            {
                                Label: "No",
                                NextQuestion: "Q6"
                            },
                        ],
                        MainQuestion: true
                    },
                    Q5: {
                        Quesition: "Okay great, what is it approximately?",
                        Answers: [{
                            FieldLabel: "Enter Number of Years",
                            ButtonLabel: "Next",
                            NextQuestion: "Q7",
                            Field: "QRSProjectedRetirementYears"
                        }, {

                            Label: "Can't provide an exact amount",
                            NextQuestion: "Q6"
                        }, ],
                        MainQuestion: false,
                    },
                    Q6: {
                        Quesition: "Is it better to find out now or when it might be too late?",
                        Answers: [{
                                Label: "Now",
                                NextQuestion: "Q100" // denotes the Reset script
                            },
                            {
                                Label: "Too Late",
                                NextQuestion: "Q7"
                            },
                        ],
                        MainQuestion: false,
                    },
                    Q7: {
                        Quesition: "Do you know how much your retirement income will decrease when the market crashes?",
                        Answers: [{
                                Label: "Yes",
                                NextQuestion: "Q8"
                            },
                            {
                                Label: "No",
                                NextQuestion: "Q9"
                            },
                        ],
                        MainQuestion: true
                    },
                    Q8: {
                        Quesition: "Okay great, what is it approximately?",
                        Answers: [{
                            FieldLabel: "Enter % Value",
                            ButtonLabel: "Next",
                            NextQuestion: "Q100", // denotes the Reset script
                            Field: "QRSRetirementDecrease"
                        }, {

                            Label: "Can't provide an exact amount",
                            NextQuestion: "Q9"
                        }, ],
                        MainQuestion: false,
                    },
                    Q9: {
                        Quesition: "Is it better to find out now or when it might be too late?",
                        Answers: [{
                                Label: "Now",
                                NextQuestion: "Q100" // denotes the Reset script
                            },
                            {
                                Label: "Too Late",
                                NextQuestion: "Q10"
                            },
                        ],
                        MainQuestion: false,
                    },
                    Q10: {
                        Quesition: "Thank you for your time. Please let me know if anything changes and we can review your position.",
                        MainQuestion: false,
                    },
                    Q100: {
                        Quesition: "We have a retirement division who’s primary focus is education. Their job is to make sure that you actually understand the vehicle that’s supposed to provide you income for the rest of your life! They’ll get you the information, and you do with it whatever you want. If I said same time next week, that would work for the two of you right.",
                        MainQuestion: true,
                    },
                },
                OverPaymentOptions: {
                    Q1: {
                        Quesition: "Do you feel like your debt is preventing you from contributing to retirement?",
                        PreviousQuestion: null,
                        MainQuestion: true
                    },
                    Q2: {
                        Quesition: "If your mortgage was paid off, would your retirement be more comfortable?",
                        PreviousQuestion: "Q1",
                        MainQuestion: true
                    },
                    Q3: {
                        Quesition: "If you had no debt, do you think you could retire sooner?",
                        PreviousQuestion: "Q2",
                        MainQuestion: true
                    },
                },
                isQRSReset: false,
                MPNotes: null,
                DFLNotes: null,
                QRSNotes: null,
            },
            emailSentMsg: null,
            emailStatus: null,
            isMP: false,
            sendingEmail: false
        }
    },
    created: function() {

    },
    mounted: function() {
        // this.reset();
    },
    computed: {
        loading: function() {
            return this.sendingEmail || this.$store.state.entities.needs_analysis_lead.loading
        }
    },
    methods: {
        nextStep: function(s) {
            var g = this;
            this.$vuetify.goTo('#stepper', {
                    duration: 500,
                    easing: 'easeOutCubic',
                    offset: 300
                })
                .then(function(x) {
                    g.step = s
                    g.trackStep()
                });
        },
        trackStep: function() {
            var cat = "Client Info";
            switch (this.step) {
                case 2:
                    cat = "Assets";
                case 3:
                    cat = "Expenses";
                case 4:
                    cat = "Debt";
                case 5:
                    cat = "Results";
            }
        },
        MPCheck: function() {
            let totalAdditionalAssets = 0
            let totalAccelerated = 0
            let overallTotal = 0

            for (const asset of this.assets.Assets) {
                totalAdditionalAssets += parseInt(asset.current_value)
            }

            // return if QRS Rest
            if (totalAdditionalAssets >= 10000) {
                return false
            }

            for (const mortgate of this.assets.Mortgage) {
                totalAccelerated += parseInt(mortgate.acceleratedAmount)
            }

            // get cc total
            for (const ccData of this.debt.CreditCardDebt) {
                if (ccData.Overpayment) {
                    overallTotal++
                    break
                }
            }

            // get student loan total
            for (const slData of this.debt.StudentLoanDebt) {
                if (slData.Overpayment) {
                    overallTotal++
                    break
                }
            }

            // get personal total
            for (const perData of this.debt.PersonalDebt) {
                if (perData.Overpayment) {
                    overallTotal++
                    break
                }
            }

            // get car loan total
            for (const clData of this.debt.CarLoanDebt) {
                if (clData.Overpayment) {
                    overallTotal++
                    break
                }
            }

            // return false if overpayment
            if (overallTotal >= 1 || totalAccelerated >= 250) {
                return false
            }

            const DFLEND = this.results.Q1 != null && this.results.Q2 != null && this.results.Q3 != null

            // return false if showing dfl script
            if (DFLEND && (this.results.Q1 == "Yes" || this.results.Q2 == "Yes" || this.results.Q3 == "Yes")) {
                return false
            }

            return true
        },
        sendEmail: function() {
            // get total amount of
            // additional assets

            var g = this
            this.sendingEmail = true

            const reducer = (accumulator, currentAmount) => {
                let commaFreeValue

                if (typeof currentAmount.current_value == "string") {
                    commaFreeValue = currentAmount.current_value.replace(/,/g, '')
                } else {
                    commaFreeValue = currentAmount
                }

                return accumulator + parseFloat(commaFreeValue)
            }

            if (this.assets.Assets.length > 0) {
                this.AdditionalAssets = this.assets.Assets.reduce(reducer, 0)
            } else {
                this.AdditionalAssets = '0.00'
            }

            this.isMP = this.MPCheck()

            // send email to QRS/DFL
            QuilityAPI.sendFIFEmail(
                this.clientContact,
                this.assets,
                this.expenses,
                this.debt,
                this.results,
                this.AdditionalAssets,
                this.user.Agent.AgentCode,
                this.isMP
            ).then(function(json) {
                //reset form values
                g.AdditionalAssets = 0
                g.sheet = false
                g.assets = {
                    Mortgage: [],
                    Insurance: [],
                    Assets: [],
                }
                g.clientContact = {
                    InsuredName: null,
                    Email: null,
                    Phone: null,
                    Street1: null,
                    Street2: null,
                    City: null,
                    States: null,
                    Zip: null,
                }
                g.debt = {
                    CreditCardDebt: [],
                    StudentLoanDebt: [],
                    PersonalDebt: [],
                    CarLoanDebt: [],
                }
                g.expenses = {
                    Assets: [],
                    ClientWages: null,
                    ClientSocialSecurity: null,
                    ClientRetirement: null,
                    SpouseWages: null,
                    SpouseSocialSecurityIncome: null,
                    SpouseRetirement: null,
                }
                g.results.Q1 = null
                g.results.Q2 = null
                g.results.Q3 = null
                g.results.QRSIncomeGap = null
                g.results.QRSProjectedRetirementYears = null
                g.results.QRSRetirementDecrease = null

                setTimeout(function() {
                    g.emailSentMsg = json.Message
                    g.emailStatus = json.Success
                    g.sendingEmail = false
                }, 7000)
            })

        },
        resetFIF: function() {

            this.AdditionalAssets = 0
            this.step = 1
            this.sheet = false
            this.emailSentMsg = null,
                this.emailStatus = null,
                this.isMP = false,
                this.sendingEmail = false

            this.assets = {
                Mortgage: [],
                Insurance: [],
                Assets: [],
            }

            this.clientContact = {
                InsuredName: null,
                Email: null,
                Phone: null,
                Street1: null,
                Street2: null,
                City: null,
                States: null,
                Zip: null,
            }

            this.debt = {
                CreditCardDebt: [],
                StudentLoanDebt: [],
                PersonalDebt: [],
                CarLoanDebt: [],
            }

            this.expenses = {
                Assets: [],
                ClientWages: null,
                ClientSocialSecurity: null,
                ClientRetirement: null,
                SpouseWages: null,
                SpouseSocialSecurityIncome: null,
                SpouseRetirement: null,
            }

            this.results = {
                Q1: null,
                Q2: null,
                Q3: null,
                QRSIncomeGap: null,
                QRSProjectedRetirementYears: null,
                QRSRetirementDecrease: null,
                QRSOptions: {
                    Q1: {
                        Quesition: "Has your current advisor shown you what your income gap will be when you retire?",
                        Answers: [{
                                Label: "Yes",
                                NextQuestion: "Q2"
                            },
                            {
                                Label: "No",
                                NextQuestion: "Q3"
                            },
                        ],
                        MainQuestion: true
                    },
                    Q2: {
                        Quesition: "Okay great, what is it approximately?",
                        Answers: [{
                            FieldLabel: "Enter Dollar Amount",
                            ButtonLabel: "Next",
                            NextQuestion: "Q4",
                            Field: "QRSIncomeGap",
                            Icon: "fas fa-dollar-sign",
                        }, {

                            Label: "Can't provide an exact amount",
                            NextQuestion: "Q3"
                        }, ],
                        MainQuestion: false
                    },
                    Q3: {
                        Quesition: "Is it better to find out what that “gap” is now or when you retire?",
                        Answers: [{
                                Label: "Now",
                                NextQuestion: "Q100" // denotes the Reset script
                            },
                            {
                                Label: "Retire",
                                NextQuestion: "Q4"
                            },
                        ],
                        MainQuestion: false
                    },
                    Q4: {
                        Quesition: "Do you know how long your retirement assets are projected to last?",
                        Answers: [{
                                Label: "Yes",
                                NextQuestion: "Q5"
                            },
                            {
                                Label: "No",
                                NextQuestion: "Q6"
                            },
                        ],
                        MainQuestion: true
                    },
                    Q5: {
                        Quesition: "Okay great, what is it approximately?",
                        Answers: [{
                            FieldLabel: "Enter Number of Years",
                            ButtonLabel: "Next",
                            NextQuestion: "Q7",
                            Field: "QRSProjectedRetirementYears"
                        }, {

                            Label: "Can't provide an exact amount",
                            NextQuestion: "Q6"
                        }, ],
                        MainQuestion: false,
                    },
                    Q6: {
                        Quesition: "Is it better to find out now or when it might be too late?",
                        Answers: [{
                                Label: "Now",
                                NextQuestion: "Q100" // denotes the Reset script
                            },
                            {
                                Label: "Too Late",
                                NextQuestion: "Q7"
                            },
                        ],
                        MainQuestion: false,
                    },
                    Q7: {
                        Quesition: "Do you know how much your retirement income will decrease when the market crashes?",
                        Answers: [{
                                Label: "Yes",
                                NextQuestion: "Q8"
                            },
                            {
                                Label: "No",
                                NextQuestion: "Q9"
                            },
                        ],
                        MainQuestion: true
                    },
                    Q8: {
                        Quesition: "Okay great, what is it approximately?",
                        Answers: [{
                            FieldLabel: "Enter % Value",
                            ButtonLabel: "Next",
                            NextQuestion: "Q100", // denotes the Reset script
                            Field: "QRSRetirementDecrease"
                        }, {

                            Label: "Can't provide an exact amount",
                            NextQuestion: "Q9"
                        }, ],
                        MainQuestion: false,
                    },
                    Q9: {
                        Quesition: "Is it better to find out now or when it might be too late?",
                        Answers: [{
                                Label: "Now",
                                NextQuestion: "Q100" // denotes the Reset script
                            },
                            {
                                Label: "Too Late",
                                NextQuestion: "Q10"
                            },
                        ],
                        MainQuestion: false,
                    },
                    Q10: {
                        Quesition: "Thank you for your time. Please let me know if anything changes and we can review your position.",
                        MainQuestion: false,
                    },
                    Q100: {
                        Quesition: "We have a retirement division who’s primary focus is education. Their job is to make sure that you actually understand the vehicle that’s supposed to provide you income for the rest of your life! They’ll get you the information, and you do with it whatever you want. If I said same time next week, that would work for the two of you right.",
                        MainQuestion: true,
                    },
                },
                OverPaymentOptions: {
                    Q1: {
                        Quesition: "Do you feel like your debt is preventing you from contributing to retirement?",
                        PreviousQuestion: null,
                        MainQuestion: true
                    },
                    Q2: {
                        Quesition: "If your mortgage was paid off, would your retirement be more comfortable?",
                        PreviousQuestion: "Q1",
                        MainQuestion: true
                    },
                    Q3: {
                        Quesition: "If you had no debt, do you think you could retire sooner?",
                        PreviousQuestion: "Q2",
                        MainQuestion: true
                    },
                },
                isQRSReset: false,
                MPNotes: null,
                DFLNotes: null,
                QRSNotes: null,
            }
        }
    },
    components: {
        Contact,
        Assets,
        Expenses,
        Debt,
        Results,
        QNewBusinessSubmenu
    }
}

</script>
<style scoped>
.v-tour__target--relative {
    margin-top: 160px;
}

</style>
