<template>
    <div>
        <q-my-clients-submenu></q-my-clients-submenu>
        <v-row class="mx-5">
            <v-col cols=12>
                <v-card class="py-2">
                    <v-alert border="left" outlined type="info" class="mb-0 mx-3">
                        <p class="mb-0">
                            Not all carriers feed their pending case data and status to Quility, but you can view this information directly on the carrier's agent portal.
                            <router-link to="/new_business/carrier_portal" class="font-weight-bold">Click here for a list of carriers and the status of received data.</router-link>
                        </p>
                    </v-alert>

                    <q-pending-policy-data-table :agent="user.Agent" :rows="25" title="Pending Policies"></q-pending-policy-data-table>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
//table data + search
import QPendingPolicyDataTable from '@/components/datatables/QPendingPolicyDataTable.vue';
import QMyClientsSubmenu from '@/components/navigation/Submenus/QMyClientsSubmenu.vue';


export default {
    components: {
        QPendingPolicyDataTable,
        QMyClientsSubmenu,
    },
}
</script>
