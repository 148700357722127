<template>
    <div class="white-background">
        <QMyClientsSubmenu />
        <v-row>
            <v-col cols=12>
                <QApplicationsAuditTable title="Submitted Applications" />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import QMyClientsSubmenu from "@/components/navigation/Submenus/QMyClientsSubmenu";
import QApplicationsAuditTable from '@/components/datatables/QApplicationsAuditTable.vue'

export default {
    name: "Applications",
    components: {
        QApplicationsAuditTable,
        QMyClientsSubmenu
    },
};

</script>
