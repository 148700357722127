<template>
    <v-container fluid>
        <p class="q-display-2 q_dark_gray--text">Results Data</p>
        <v-row>
            <v-col cols="6">
                <v-card elevate="0" color="q_new_b_4" class="text-center pa-3 mb-3"><span class="display-1 font-weight-bold">{{ formatCurrency(lump_sump_costs_total) }}</span><br>Lump Sum Costs at Death</v-card>
                <v-card elevate="0" color="q_new_b_4" class="text-center pa-3 mb-3"><span class="display-1 font-weight-bold">{{ formatCurrency(income_replacement_need) }}</span><br>Income Replacement Need</v-card>
            </v-col>
            <v-col cols="6">
                <q-pie-chart-generic :data="liabilities" title="Cash Required at Death" name="Cash Required" :format="formatCurrency"></q-pie-chart-generic>
            </v-col>
            <v-col cols="6" class="text-center">
                <v-card elevate="0" color="q_new_b_4" class="text-center pa-3 mb-3"><span class="display-1 font-weight-bold">{{ formatCurrency(cash_shortfall) }}</span><br>Shortfall</v-card>
                Option 1: Cover Entire Shortfall
                <v-btn dark color="q_new_b_1" href="https://sfgvirtualmentor.com/ipq/ipq_page" large block class="mb-6" target="_tab">{{ formatCurrency(cash_shortfall) }} Policies<v-icon class="pl-3">fas fa-chevron-right</v-icon>
                </v-btn>
                Option 2: Primary Residence Only
                <v-btn dark color="q_new_b_1" href="https://sfgvirtualmentor.com/ipq/ipq_page" large block class="mb-6" target="_tab">{{ formatCurrency(lead.RealEstate.PrimaryHomeValue) }} Policies<v-icon class="pl-3">fas fa-chevron-right</v-icon>
                </v-btn>
                Option 2: Half of Mortgage
                <v-btn dark color="q_new_b_1" href="https://sfgvirtualmentor.com/ipq/ipq_page" large block class="mb-6" target="_tab">{{ formatCurrency(lead.RealEstate.PrimaryHomeValue / 2) }} Policies<v-icon class="pl-3">fas fa-chevron-right</v-icon>
                </v-btn>
            </v-col>
            <v-col cols="6">
                <q-pie-chart-generic :data="cash_shortfall_data" title="Shortfall" name="shortfall" :format="formatCurrency"></q-pie-chart-generic>
            </v-col>
            <v-col cols="12">
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import QPieChartGeneric from '@/components/charts/QPieChartGeneric.vue'

export default {
    props: ['lead'],
    data: function() {
        return {

        }
    },
    computed: {
        assets: function() {
            var a = [];
            this.pushLineItem(a, this.lead.LifeInsurance, "PrimaryInsuranceValue", 'Primary Life Insurance');
            this.pushLineItem(a, this.lead.LifeInsurance, "OtherInsuranceValue", 'Other Life Insurance');
            this.pushLineItem(a, this.lead.LifeInsurance, "EmployerLifeInsurance", 'Employer Life Insurance');
            this.pushLineItem(a, this.lead.Assets, "Funds", 'Funds');
            for (var i = 0; i < this.lead.Assets.length; i++) {
                this.pushLineItem(a, this.lead.Assets[i], "current_value", this.lead.Assets[i].AssetType);
            }
            if (this.lead.Income.InvestmentIncome > 0) {
                a.push({
                    name: "Investment Income",
                    y: this.lead.Income.ReplacementYears * this.lead.Income.InvestmentIncome
                })
            }
            return a;
        },
        cash_shortfall: function() {
            return this.liabilities_total - this.assets_total;
        },
        cash_shortfall_data: function() {
            var a = [];
            this.pushLineItem(a, this.lead.LifeInsurance, "PrimaryInsuranceValue", 'Primary Life Insurance');
            this.pushLineItem(a, this.lead.LifeInsurance, "OtherInsuranceValue", 'Other Life Insurance');
            this.pushLineItem(a, this.lead.LifeInsurance, "EmployerLifeInsurance", 'Employer Life Insurance');
            this.pushLineItem(a, this.lead.Assets, "Funds", 'Funds');
            for (var i = 0; i < this.lead.Assets.length; i++) {
                this.pushLineItem(a, this.lead.Assets[i], "current_value", this.lead.Assets[i].AssetType);
            }
            a.push({
                name: "Shortfall",
                y: this.cash_shortfall
            })
            return a
        },
        assets_total: function() {
            var t = 0;
            for (var i = 0; i < this.assets.length; i++) {
                t = t + parseFloat(this.assets[i].y)
            }
            return t
        },
        liabilities: function() {
            var l = [];
            this.pushLineItem(l, this.lead.RealEstate, "PrimaryHomeLoanBalance", 'Primary Home Mortgage Balance');
            this.pushLineItem(l, this.lead.RealEstate, "AdditionalProperty1LoanBalance", 'Additional Property Mortgage Balance');
            this.pushLineItem(l, this.lead.RealEstate, "AdditionalProperty2LoanBalance", 'Additional Property Mortgage Balance');
            this.pushLineItem(l, this.lead.RealEstate, "FinalExpense", 'Final Expense');
            this.pushLineItem(l, this.lead.RealEstate, "AdditionalDebt", 'Additional Debt');
            this.pushLineItem(l, this.lead.RealEstate, "FutureFunds", 'Future Funds');
            for (var i = 0; i < this.lead.Debts.length; i++) {
                this.pushLineItem(l, this.lead.Debts[i], "payoff_amt", this.lead.Debts[i].DebtType);
            }
            if (this.income_replacement_need > 0) {
                l.push({
                    name: "Replacement Income",
                    y: this.income_replacement_need
                })
            }
            return l;
        },
        liabilities_total: function() {
            var t = 0;
            for (var i = 0; i < this.liabilities.length; i++) {
                t = t + parseFloat(this.liabilities[i].y)
            }
            return t
        },
        income_replacement_need: function() {
            return parseInt(this.lead.Income.ReplacementYears) * (parseFloat(this.lead.Income.AnnualIncome) + parseFloat(this.lead.Income.Pension))
        },
        lump_sump_costs: function() {
            var l = [];
            this.pushLineItem(l, this.lead.RealEstate, "PrimaryHomeLoanBalance", 'Primary Home Mortgage Balance');
            this.pushLineItem(l, this.lead.RealEstate, "AdditionalProperty1LoanBalance", 'Additional Property Mortgage Balance');
            this.pushLineItem(l, this.lead.RealEstate, "AdditionalProperty2LoanBalance", 'Additional Property Mortgage Balance');
            this.pushLineItem(l, this.lead.RealEstate, "AdditionalDebt", 'Additional Debt');
            this.pushLineItem(l, this.lead.RealEstate, "FutureFunds", 'Future Funds');
            this.pushLineItem(l, this.lead.RealEstate, "FinalExpense", 'Final Expense');
            return l;
        },
        lump_sump_costs_total: function() {
            var t = 0;
            for (var i = 0; i < this.lump_sump_costs.length; i++) {
                t = t + parseFloat(this.lump_sump_costs[i].y)
            }
            return t
        },

    },
    methods: {
        pushLineItem: function(arr, obj, key, label) {
            if (obj[key] > 0) {
                arr.push({
                    name: label,
                    y: parseFloat(obj[key])
                })
            }
        },
    },
    components: {
        QPieChartGeneric
    }
}

</script>
