<template>
    <v-container fluid class="white">
        <v-card justify="start" align="start" class="px-7">
            <v-row justify="start" align="start">
                <v-col cols="12">
                    <p class="q-display-2 q_dark_gray--text">Client Info<span v-if="lead.Applicant">: {{ lead.Applicant }} {{ lead.LeadID }}</span>
                    </p>
                    <p v-if="lead.Agent">Agent: {{ lead.Agent }}</p>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                    <v-text-field v-model="lead.Contact" label="Name"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                    <v-text-field v-model="lead.Agent" label="Agent"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                    <v-text-field v-model="lead.Phone" label="Phone"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                    <v-text-field v-model="lead.Email" label="Email"></v-text-field>
                </v-col>
            </v-row>
            <v-row justify="start" align="start">
                <v-col cols="12" sm="6" md="4" lg="3">
                    <v-text-field v-model="lead.Street1" label="Address"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                    <v-text-field v-model="lead.City" label="City"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                    <v-text-field v-model="lead.State" label="State"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                    <v-text-field v-model="lead.Zip" label="Zip"></v-text-field>
                </v-col>
            </v-row>
            <v-row justify="start" align="start">
                <v-col cols="12" sm="6" md="4" lg="3">
                    <v-text-field v-model="lead.Age" label="Age"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                    <v-text-field v-model="lead.DOB" label="DOB (yyyy/mm/dd)"></v-text-field>
                </v-col>
            </v-row>
            <v-row justify="start" align="start">
                <v-col cols="12" sm="6" md="4" lg="3">
                    <v-text-field v-model="lead.Occupation" label="Occupation"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                    <v-text-field v-model="lead.YearsWith" label="# of years with employer"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                    <v-text-field v-model="lead.RetAge" label="Target Retirement Age"></v-text-field>
                </v-col>
            </v-row>
        </v-card>
        <!--spouse -->
        <v-card justify="start" align="start" class="px-7 mt-7">
            <v-row>
                <v-col cols="12">
                    <p class="q-display-2 q_dark_gray--text">Spouse info</p>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="6" md="4" lg="3">
                    <v-text-field v-model="lead.Spouse.Name" label="Full Name"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                    <v-text-field v-model="lead.Spouse.Phone" label="Phone"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                    <v-text-field v-model="lead.Spouse.Email" label="Email"></v-text-field>
                </v-col>
            </v-row>
            <v-row justify="start" align="start">
                <v-col cols="12" sm="6" md="4" lg="3">
                    <v-text-field v-model="lead.Spouse.Address" label="Address"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                    <v-text-field v-model="lead.Spouse.City" label="City"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                    <v-text-field v-model="lead.Spouse.State" label="State"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                    <v-text-field v-model="lead.Spouse.Zip" label="Zip"></v-text-field>
                </v-col>
            </v-row>
            <v-row justify="start" align="start">
                <v-col cols="12" sm="6" md="4" lg="3">
                    <v-text-field number v-model="lead.Spouse.Age" label="Age"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                    <v-text-field v-model="lead.Spouse.DOB" label="DOB (yyyy/mm/dd)"></v-text-field>
                </v-col>
            </v-row>
            <v-row justify="start" align="start">
                <v-col cols="12" sm="6" md="4" lg="3">
                    <v-text-field v-model="lead.Spouse.Occupation" label="Occupation"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                    <v-text-field number v-model="lead.Spouse.YearsWithEmployer" label="# of years with employer"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                    <v-text-field number v-model="lead.Spouse.RetirementAge" label="Target Retirement Age"></v-text-field>
                </v-col>
            </v-row>
        </v-card>
        <!--end spouse -->
        <v-row class="pt-5" justify="space-between" align="start" v-if="lead.Applicant">
            <v-expansion-panels>
                <v-expansion-panel>
                    <v-expansion-panel-header>All raw data from applicant model</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row justify="space-between" align="start">
                            <v-col cols="3" sm="2" md="1" v-for="(value, key) in lead" :key="key" class="q-overline">
                                <strong>{{key}}:</strong><br>{{ value }}</v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-row>
    </v-container>
</template>
<script>
export default {
    props: ['lead']
}

</script>
