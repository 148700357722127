<template>
    <v-container fluid class="q-sticky-buffer">
        <!-- help and wayfinding -->
        <!--
                <v-col cols="12" class="text-right">
                    <v-bottom-sheet v-model="sheet">
                        <template v-slot:activator="{ on }">
                            <v-btn icon color="pink" v-on="on">
                              <v-icon>fas fa-question-circle</v-icon>
                            </v-btn>
                        </template>
                        <v-sheet class="text-left px-7" height="auto">
                            <div class="py-3" style="max-width:900px; margin:0 auto;">
                                <p class="q-display-2">Needs Analysis</p>
                                <p>This section mirrors the functions of Needs Analysis from Virtual Mentor.</p>
                                    <ul>
                                        <li>Client info can be pushed to this form from the Leads section</li>
                                        <li>Leads can be searched and have the client data pulled into the form</li>
                                        <li>Follow the steps to find suggest coverage amounts unique to your client</li>
                                        <li>Client info can then be pushed to the Policy Finder</li>
                                    </ul>
                                <v-btn class="mt-6" flat color="error" @click="sheet = !sheet">close</v-btn>
                            </div>
                        </v-sheet>
                    </v-bottom-sheet>
                </v-col>
            -->
        <!-- /help and wayfinding -->
        <v-row class="mx-3 mb-0 pa-0 q_new_b_1">
            <v-col id="v-na-1" cols="8" class="pl-1 py-0">
                <p class="q-display-3 pl-5 py-2 white--text">
                    Needs Analysis
                    <v-btn @click="startNewClient()">New Client
                        <v-icon right>fas fa-plus-circle</v-icon>
                    </v-btn>
                </p>
            </v-col>
            <q-search-needs-analysis-lead></q-search-needs-analysis-lead>
            <v-col cols="12" class="pa-0 ma-0">
                <div class="text-center">
                    <v-progress-linear height="12" indeterminate v-if="loading"></v-progress-linear>
                </div>
            </v-col>
        </v-row>
        <v-stepper v-if="lead" v-model="step" id="stepper" class="mx-3 mt-0">
            <v-stepper-header>
                <v-stepper-step color="q_new_b_3" :complete="step > 1" step="1" @click="nextStep(1)" class="pointer">Client Info</v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step color="q_new_b_3" :complete="step > 2" step="2" @click="nextStep(2)" class="pointer">Real Estate</v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step color="q_new_b_3" :complete="step > 3" step="3" @click="nextStep(3)" class="pointer">Insurance</v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step color="q_new_b_3" :complete="step > 4" step="4" @click="nextStep(4)" class="pointer">Assets</v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step color="q_new_b_3" :complete="step > 5" step="5" @click="nextStep(5)" class="pointer">Income</v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step color="q_new_b_3" :complete="step > 6" step="6" @click="nextStep(6)" class="pointer">Results</v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
                <v-stepper-content step="1">
                    <v-row wrap>
                        <v-col cols="12">
                            <client-info :lead.sync="lead"></client-info>
                            <v-row cols="12" class="my-5 px-4 d-flex justify-space-between">
                                <v-spacer></v-spacer>
                                <v-btn dark color="q_new_b_1" @click="nextStep(2)">
                                    next<v-icon class="pl-3">fas fa-chevron-right</v-icon>
                                </v-btn>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-stepper-content>
                <v-stepper-content step="2">
                    <v-col cols="12">
                        <real-estate :lead.sync="lead"></real-estate>
                        <v-row cols="12" class="my-5 px-4 d-flex justify-space-between">
                            <v-btn outlined color="q_new_b_1" @click="nextStep(1)">
                                <v-icon class="pr-3">fas fa-chevron-left</v-icon>back
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn dark color="q_new_b_1" @click="nextStep(3)">
                                next<v-icon class="pl-3">fas fa-chevron-right</v-icon>
                            </v-btn>
                        </v-row>
                    </v-col>
                </v-stepper-content>
                <v-stepper-content step="3">
                    <v-col cols="12">
                        <insurance :lead.sync="lead"></insurance>
                        <v-row cols="12" class="my-5 px-4 d-flex justify-space-between">
                            <v-btn outlined color="q_new_b_1" @click="nextStep(2)">
                                <v-icon class="pr-3">fas fa-chevron-left</v-icon>back
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn dark color="q_new_b_1" @click="nextStep(4)">
                                next<v-icon class="pl-3">fas fa-chevron-right</v-icon>
                            </v-btn>
                        </v-row>
                    </v-col>
                </v-stepper-content>
                <v-stepper-content step="4">
                    <v-col cols="12">
                        <assets :lead.sync="lead"></assets>
                        <v-row cols="12" class="my-5 px-4 d-flex justify-space-between">
                            <v-btn outlined color="q_new_b_1" @click="nextStep(3)">
                                <v-icon class="pr-3">fas fa-chevron-left</v-icon>back
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn dark color="q_new_b_1" @click="nextStep(5)">
                                next<v-icon class="pl-3">fas fa-chevron-right</v-icon>
                            </v-btn>
                        </v-row>
                    </v-col>
                </v-stepper-content>
                <v-stepper-content step="5">
                    <v-col cols="12">
                        <income :lead.sync="lead"></income>
                        <v-row cols="12" class="my-5 px-4 d-flex justify-space-between">
                            <v-btn outlined color="q_new_b_1" @click="nextStep(4)">
                                <v-icon class="pr-3">fas fa-chevron-left</v-icon>back
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn dark color="q_new_b_1" @click="nextStep(6)">
                                next<v-icon class="pl-3">fas fa-chevron-right</v-icon>
                            </v-btn>
                        </v-row>
                    </v-col>
                </v-stepper-content>
                <v-stepper-content step="6">
                    <v-col cols="12">
                        <results :lead.sync="lead"></results>
                        <v-row cols="12" class="my-5 d-flex justify-space-between">
                            <v-btn outlined color="q_new_b_1" @click="nextStep(5)">
                                <v-icon class="pr-3">fas fa-chevron-left</v-icon>back
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn dark color="q_new_b_1" href="https://sfgvirtualmentor.com/ipq/ipq_page">
                                Find a Policy<v-icon class="pl-3">fas fa-chevron-right</v-icon>
                            </v-btn>
                        </v-row>
                    </v-col>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
    </v-container>
</template>
<script>
import ClientInfo from './NeedsAnalysis/ClientInfo.vue';
import RealEstate from './NeedsAnalysis/RealEstate.vue';
import Insurance from './NeedsAnalysis/Insurance.vue';
import Assets from './NeedsAnalysis/Assets.vue';
import Income from './NeedsAnalysis/Income.vue';
import Results from './NeedsAnalysis/Results.vue';
import QSearchNeedsAnalysisLead from '@/components/utils/QSearchNeedsAnalysisLead.vue'
import NeedsAnalysisLead from '@/store/Models/NeedsAnalysisLead.js'
import QuilityAPI from '@/store/API/QuilityAPI.js'

export default {
    data() {
        return {
            step: 1,
            sheet: false,
            lead_id: null,
            lead: null,
        }
    },
    created: function() {

    },
    mounted: function() {
        this.reset();
    },
    computed: {
        loading: function() {
            return this.$store.state.entities.needs_analysis_lead.loading
        }
    },
    methods: {
        nextStep: function(s) {
            var g = this;
            this.$vuetify.goTo('#stepper', {
                duration: 500,
                easing: 'easeOutCubic',
                offset: 300
            }).then(function(x) {
                if (g.lead_id == null) {
                    QuilityAPI.createMyNeedsAnalysisLead(g.lead).then(function(lead) {
                        g.step = s;
                        g.reset(lead.LeadID);
                        //g.showSuccess("New lead created!");
                        g.trackNewLead()
                    }).catch(function(err) {
                        if (err.msg) {
                            g.showError(err.msg + " " + err.errors);
                            NeedsAnalysisLead.commit((state) => {
                                state.loading = false
                            })
                        }
                    })
                } else {
                    QuilityAPI.updateMyNeedsAnalysisLead(g.lead).then(function(lead) {
                        g.step = s;
                        g.reset(lead.LeadID);
                        //g.showSuccess("Changes saved!");
                        g.trackStep();
                    }).catch(function(err) {
                        console.log("err", err)
                        if (err.msg) {
                            g.showError(err.msg + " " + err.errors);
                            NeedsAnalysisLead.commit((state) => {
                                state.loading = false
                            })
                        }
                    })
                }
            });
        },
        trackNewLead: function() {
            gtag('event', 'Needs Analysis', {
                'event_category': 'New Lead Created',
                'event_label': this.lead_id + " " + this.lead.Contact
            });
        },
        trackStep: function() {
            var cat = "Client Info";
            switch (this.step) {
                case 2:
                    cat = "Real Estate";
                case 3:
                    cat = "Insurance";
                case 4:
                    cat = "Assets";
                case 5:
                    cat = "Income";
                case 6:
                    cat = "Results";
            }
            gtag('event', 'Needs Analysis', {
                'event_category': cat,
                'event_label': this.lead_id + " " + this.lead.Contact
            });
        },
        startNewClient: function() {
            if (this.$route.params.lead_id > 0) {
                this.$router.push('/new_business/workflow/needs-analysis')
            }
            this.reset();
        },
        reset: function(id) {
            var g = this;
            if (typeof id != 'undefined') {
                this.lead_id = id
            } else {
                this.lead_id = this.$route.params.lead_id;
            }
            if (this.lead_id) {
                var l = NeedsAnalysisLead.find(this.lead_id);
                if (l != null) {
                    g.$set(g, 'lead', l)
                    return
                }
                //load lead from API or 404 that shit.
                QuilityAPI.getMyNeedsAnalysisLead(this.lead_id).then(
                    function(lead) {
                        if (lead) {
                            g.$set(g, 'lead', lead)
                            g.step = 1
                        } else {
                            g.$router.push('/404');
                        }
                    },
                    function(error) {
                        console.log(error);
                        g.showError(error.msg);
                    })
            } else {
                NeedsAnalysisLead.new().then((item) => {
                    g.$emit('lead', item.$id);
                    g.lead = item;
                    g.step = 1
                });
            }
            this.sheet = false
        }
    },
    watch: {
        '$route.params.lead_id': function(newV, oldV) {
            this.step = 1
            this.reset();
            return;
            var g = this;
            if (typeof newV != 'undefined' && this.lead_id != newV) {
                this.reset();
            } else {

            }
            this.page = 1
        },
        'lead.LeadID': function(newV, oldV) {
            if (typeof newV != 'undefined' && newV != null && oldV == null && typeof oldV != 'undefined') {
                this.$router.push('/new_business/workflow/' + newV + "/needs-analysis")
            }
        },
    },
    components: {
        ClientInfo,
        RealEstate,
        Insurance,
        Assets,
        Income,
        Results,
        QSearchNeedsAnalysisLead
    }
}

</script>
<style scoped>
.v-tour__target--relative {
    margin-top: 160px;
}

</style>
