<template>
    <v-container fluid>
        <v-row cols="12" class="d-flex justify-space-around ">
            <v-col cols="12">
                <p class="q-display-2 q_dark_gray--text">Income & Expenses</p>
            </v-col>
        </v-row>
        <v-row class="">
            <!--Monthly Expenses -->
            <v-col cols="12" sm="4" class="pa-5  mt-7 ">
                <div class="">
                    <h4 class="q_dark_gray--text">Monthly Expenses</h4>
                </div>
                <v-row>
                    <v-col>
                        <v-simple-table style="background: transparent;">
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Type
                                    </th>
                                    <th class="text-left">
                                        Amount
                                    </th>
                                </tr>
                            </thead>
                        </v-simple-table>
                        <v-card cols="12" flat class="px-4 q-alt-rows" v-for="(asset, key) in expenses.Assets" :key="key">
                            <v-row>
                                <v-simple-table style="background: transparent;">
                                    <tbody>
                                        <tr>
                                            <td class="px-5">
                                                <v-select :items="ExpenseTypes" v-model="asset.AssetType"></v-select>
                                            </td>
                                            <td class="px-5">
                                                <v-text-field v-model="asset.payment" prepend-icon="fas fa-file-invoice-dollar" label="Payment" @blur="updateAssetFormat(key)" @focus="updateAssetFormat(key)"></v-text-field>
                                            </td>
                                            <td>
                                                <v-btn fab icon color="red" @click="deleteAsset(key)">
                                                    <v-icon>fas fa-times-circle</v-icon>
                                                </v-btn>
                                            </td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row class="">
                    <v-btn depressed text right color="primary" @click="addAsset">Add an expense
                        <v-icon right>fas fa-plus-circle</v-icon>
                    </v-btn>
                </v-row>
            </v-col>
            <!--/Monthly Expenses -->
            <!--Combined income -->
            <v-col cols="12" sm="4" class="pa-5 mt-7 ">
                <div class="">
                    <h4 class="q_dark_gray--text">Income</h4>
                </div>
                <v-row>
                    <v-col>
                        <v-card cols="12" flat class="px-4 q-alt-rows">
                            <v-row>
                                <v-simple-table>
                                    <tbody>
                                        <tr>
                                            <td class="px-5">
                                                <v-text-field v-model="expenses.ClientWages" prepend-icon="fas fa-file-invoice-dollar" label=" Client 1 Wages"></v-text-field>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="px-5">
                                                <v-text-field v-model="expenses.ClientSocialSecurity" prepend-icon="fas fa-file-invoice-dollar" label="Client 1 Social Security"></v-text-field>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="px-5">
                                                <v-text-field v-model="expenses.ClientRetirement" prepend-icon="fas fa-file-invoice-dollar" label="Client 1 Pension/Retirement"></v-text-field>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="px-5">
                                                <v-text-field v-model="expenses.SpouseWages" prepend-icon="fas fa-file-invoice-dollar" label="Client 2 Wages"></v-text-field>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="px-5">
                                                <v-text-field v-model="expenses.SpouseSocialSecurityIncome" prepend-icon="fas fa-file-invoice-dollar" label="Client 2 Social Security Income"></v-text-field>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="px-5">
                                                <v-text-field v-model="expenses.SpouseRetirement" prepend-icon="fas fa-file-invoice-dollar" label="Client 2 Pension/Retirement"></v-text-field>
                                            </td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
            <!--/Combined Income -->
            <!--Survivors Income -->
            <v-col cols="12" sm="4" class="pa-5  mt-7 ">
                <div class="">
                    <h4 class="q_dark_gray--text">Totals</h4>
                </div>
                <v-row>
                    <v-col>
                        <v-simple-table>
                            <tbody>
                                <tr>
                                    <td class="px-5 pt-5">
                                        <p>Client 1 Income<br />
                                            <em>(without client 2)</em></p>
                                    </td>
                                    <td class="px-5 pt-5">
                                        ${{ ClientIncome }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="px-5 pt-5">
                                        <p>Client 2 Income<br />
                                            <em>(without client 1)</em></p>
                                    </td>
                                    <td class="px-5 pt-5">
                                        ${{ SpouseIncome }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="px-5 pt-5">
                                        <p>Total Income</p>
                                    </td>
                                    <td class="px-5 pt-5">
                                        ${{ TotalIncome }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="px-5 pt-5">
                                        <p>Expenses</p>
                                    </td>
                                    <td class="px-5 pt-5">
                                        <p style="color:red;"> ( ${{ Expenses }} )</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="px-5 pt-5">
                                        <p>Income after Expenses</p>
                                    </td>
                                    <td class="px-5 pt-5">
                                        <p> ( ${{ IncomeAfterExpenses }} )</p>
                                    </td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </v-col>
            <!--/Survivors Income -->
        </v-row>
    </v-container>
</template>
<script>
import { formatField } from '@/components/utils/FormatNeedsAnalysisFields'
export default {
    data: () => {
        return {
            ExpenseTypes: [
                "Mortgage", "Car", "Utilties", "Other Insurances", "Credit Card Debt", "Other"
            ],
            DebtTypes: [
                "Credit Card", "Student Loan", "Personal", "Car Loan"
            ]
        }
    },
    mounted: function() {

    },
    computed: {
        ClientIncome: function() {
            const val1 = this.expenses.ClientWages ? parseFloat(this.expenses.ClientWages) : 0
            const val2 = this.expenses.ClientSocialSecurity ? parseFloat(this.expenses.ClientSocialSecurity) : 0
            const val3 = this.expenses.ClientRetirement ? parseFloat(this.expenses.ClientRetirement) : 0

            return formatField(null, (val1 + val2 + val3), true)
        },

        SpouseIncome: function() {
            const val1 = this.expenses.SpouseWages ? parseFloat(this.expenses.SpouseWages) : 0
            const val2 = this.expenses.SpouseSocialSecurityIncome ? parseFloat(this.expenses.SpouseSocialSecurityIncome) : 0
            const val3 = this.expenses.SpouseRetirement ? parseFloat(this.expenses.SpouseRetirement) : 0

            return formatField(null, (val1 + val2 + val3), true)
        },

        TotalIncome: function() {
            const val1 = this.expenses.ClientWages ? parseFloat(this.expenses.ClientWages) : 0
            const val2 = this.expenses.ClientSocialSecurity ? parseFloat(this.expenses.ClientSocialSecurity) : 0
            const val3 = this.expenses.ClientRetirement ? parseFloat(this.expenses.ClientRetirement) : 0
            const val4 = this.expenses.SpouseWages ? parseFloat(this.expenses.SpouseWages) : 0
            const val5 = this.expenses.SpouseSocialSecurityIncome ? parseFloat(this.expenses.SpouseSocialSecurityIncome) : 0
            const val6 = this.expenses.SpouseRetirement ? parseFloat(this.expenses.SpouseRetirement) : 0

            return formatField(null, (val1 + val2 + val3 + val4 + val5 + val6), true)
        },

        Expenses: function() {
            let expense = 0
            const reducer = (accumulator, currentAmount) => {
                let commaFreeValue

                if (typeof currentAmount.payment == "string") {
                    commaFreeValue = currentAmount.payment.replace(/,/g, '')
                } else {
                    commaFreeValue = currentAmount
                }

                return accumulator + parseFloat(commaFreeValue)
            }

            if (this.expenses.Assets.length > 0) {
                expense = this.expenses.Assets.reduce(reducer, 0)
            }

            return this.expenses.Assets.length > 0 ? formatField(null, expense, true) : 0
        },

        IncomeAfterExpenses: function() {
            let expense = 0
            const reducer = (accumulator, currentAmount) => {
                let commaFreeValue

                if (typeof currentAmount.payment == "string") {
                    commaFreeValue = currentAmount.payment.replace(/,/g, '')
                } else {
                    commaFreeValue = currentAmount
                }

                return accumulator + parseFloat(commaFreeValue)
            }

            if (this.expenses.Assets.length > 0) {
                expense = this.expenses.Assets.reduce(reducer, 0)
            }

            let income = this.expenses.ClientWages ? parseFloat(this.expenses.ClientWages) : 0
            income += this.expenses.ClientSocialSecurity ? parseFloat(this.expenses.ClientSocialSecurity) : 0
            income += this.expenses.ClientRetirement ? parseFloat(this.expenses.ClientRetirement) : 0
            income += this.expenses.SpouseWages ? parseFloat(this.expenses.SpouseWages) : 0
            income += this.expenses.SpouseSocialSecurityIncome ? parseFloat(this.expenses.SpouseSocialSecurityIncome) : 0
            income += this.expenses.SpouseRetirement ? parseFloat(this.expenses.SpouseRetirement) : 0

            income -= expense

            return formatField(null, income, true)
        }
    },
    methods: {
        addAsset: function() {
            this.expenses.Assets.push({
                AssetType: "401k",
                current_value: 0,
            })
        },
        deleteAsset: function(key) {
            this.expenses.Assets.splice(key, 1)
        },
        updateAssetFormat: function(key) {
            this.expenses.Assets[key].current_value = formatField('current_value', this.expenses.Assets[key])
        }
    },
    props: ['expenses'],
    watch: {
        'expenses': function(newV) {

        }
    }
}

</script>
