<template>
    <div style="background-color:transparent;">
        <v-card class="mx-7 mb-8">
            <QDataTableHeader color="q_leads_1" :id="entity + '_datatable'">
                <p class="q-display-3 mb-0 white--text">{{ title }}</p>
            </QDataTableHeader>

            <v-row>
                <v-col cols="12" sm="6" class="mb-0 pb-0 px-7">
                    <p class="q-display-3  pb-0 pt-0 mt-0 mb-0">Total For {{ pdMonth }}: {{ totalEarnedCredit }}</p>
                </v-col>
                <v-col cols="12" sm="6" class="pr-8 pl-7 pr-3 mb-0 pb-0 d-flex justify-end">
                    <v-spacer></v-spacer>
                    <v-select v-model="pdStartDate" label="Month" :items="pastMonths"></v-select>
                </v-col>
            </v-row>
            <QExpandableDataTable
                id="commission_datatable"
                fluid
                :items-per-page.sync="rows"
                :search="search"
                :headers="the_headers"
                :items="all_data"
                item-key="ID"
                class="elevation-0"
                :loading="data_loading"
                :options.sync="options"
                :hide-default-footer="true"
                :disable-pagination="true"
                :visibleHeaders="visibleHeaders"
            >
                <template v-slot:item.AccountingCycle="{ item }">
                    <span>{{getMonth(item.AccountingCycle)}}</span>
                </template>
            </QExpandableDataTable>
        </v-card>
    </div>
</template>
<script>
import QDataTableMixin from "./QDataTableMixin.js"
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { debounce } from 'vue-debounce'
import moment from 'moment'
import QDataTableHeader from '../datatables/base/QDataTableHeader.vue'
import QExpandableDataTable from "./QExpandableDataTable.vue"

export default {
    //override this.
    name: "QAgentCommissionDatatable",
    mixins: [QDataTableMixin],
    props: ['agent'],
    data: function() {
        return {
            entity: 'commission',
            response_data: [],
            totalEarnedCredit: 0,
            data_loading: true,
            firstPull: true,
            pdStartDate: moment().startOf('month').format('YYYY-MM-DD'),
            pdEndDate: moment().endOf('month').format('YYYY-MM-DD'),
            pastMonths: [],
            pdMonth: moment().add(1, 'M').format("MMMM"),
            monthIdx: null,
        }
    },
    mounted: function() {
        this.getLast12Months()
    },
    computed: {
        filter_keys: function() {
            // these should match up with the keys for the model on the server side - availableFilters
            return [ ];
        },
        all_data: function() {
            //instead of using a state/model on the frontend the data is all contained in this module.
            //this computed propery is required by the QDataTableMixin
            return this.response_data
        },
        //set these headers to match what columns you want to show in the data table.
        the_headers: function() {
            return [
                {
                    text: 'Agent Name',
                    value: 'AgentName',
                    align: 'start',
                    sortable: true,
                    divider: true,
                    filterable: false
                },
                {
                    text: 'Policy Holder Name',
                    value: 'PolicyHolderName',
                    align: 'start',
                    sortable: true,
                    divider: true,
                    filterable: false
                },
                {
                    text: 'Carrier',
                    value: 'CarrierName',
                    align: 'start',
                    sortable: true,
                    filterable: false,
                    divider: true,
                    class: 'header-class',
                },
                {
                    text: 'Accounting Cycle',
                    value: 'AccountingCycle',
                    align: 'start',
                    sortable: true,
                    divider: true,
                    filterable: false
                },
                {
                    text: 'Product',
                    value: 'ProductName',
                    align: 'start',
                    sortable: true,
                    divider: true,
                    filterable: false
                }, {
                    text: 'Earned Credit',
                    value: 'DisplayCredit',
                    align: 'start',
                    sortable: true,
                    filterable: false,
                    divider: true
                },
            ]
        },
        visibleHeaders() {
            if (this.$vuetify.breakpoint.smAndDown) {
                return [this.the_headers[0], this.the_headers.slice(-1)[0]]
            }
            if (this.$vuetify.breakpoint.mdAndDown) {
                return [...this.the_headers.slice(0, 2), this.the_headers.slice(-1)[0]]
            }

            return this.the_headers
        },
        //server_available_filters will be set by our first data pull where the server responds with all data for the filter drop downs.
        //QDataTableMixin requires this computed property.
        'available_filters': function() {
            return ['startDate','endDate']
        },
        is_loading: function() {
            return this.data_loading
        },
    },
    methods: {
        //get called by the QDataTableMixin whenever we need to refresh the data from the server.
        loadData: debounce(function(opt) {
            var g = this
            this.data_loading = true;
            var formatter = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD',})

            QuilityAPI.getAgentCommision(this.agent.AgentCode, {startDate: this.pdStartDate, endDate: this.pdEndDate}, opt)
            .then(function(json){
                g.$set(g,'response_data',json.data)

                let total = 0
                for (const comissionData of json.data) {
                    total += comissionData.EarnedCredit
                    comissionData.DisplayCredit = formatter.format(comissionData.EarnedCredit)
                }

                g.totalEarnedCredit = formatter.format(total)
                g.pdMonth = moment(g.pdStartDate).add(1, 'M').format("MMMM")
                g.data_loading = false
            })

            this.firstPull = false
        }, 200),
        exportResults: function() {
            this.is_exporting = true
            this.refreshData();
        },
        getLast12Months: function() {
            let curMonth = new Date()
            let stopDate = new Date()
            let pastMonths = []

            if(this.agent.FirstCommissionAccountingCycle) {
                stopDate = new Date(this.agent.FirstCommissionAccountingCycle.substr(0,10))
            }

            curMonth.setDate(1)
            stopDate.setMonth(stopDate.getMonth() - 1)
            let i = 0;
            while(true && i < 360) {
                if(moment(curMonth).isBefore(moment(stopDate))){
                    break
                }

                pastMonths.push({
                    'text': moment(curMonth).format('MMM YYYY'),
                    'value': moment(curMonth).format('YYYY-MM-DD'), // also the start date
                    'end': moment(curMonth).endOf('month').format('YYYY-MM-DD')
                })
                curMonth.setMonth(curMonth.getMonth() - 1)
                i++
            }

            this.pastMonths = pastMonths
        },
        getMonth: function(date) {
            return moment(date).format("MMMM")
        },
    },
    watch: {
        'agent.AgentCode': function(newV) {
            this.$nextTick(this.refreshData) // this is the QDataTableMixin call that does stuff needed before actually calling loadData above.
        },
        'pdStartDate': function(startDate) {
            const monthData = this.pastMonths.find(month => month.value == startDate)
            this.pdEndDate = monthData.end
            this.$nextTick(this.refreshData)
        }
    },
    components: {
        QDataTableHeader, QExpandableDataTable
    }
}

</script>
<style scoped>
.nowrap {
    white-space: nowrap;
}
</style>
