<template>
    <v-container fluid class="grey lighten-5 px-7">
        <v-row justify="space-between" align="start">
            <v-col cols="12">
                <h2>Application
                    <span v-if="lead">
                        for <em>{{ lead.Applicant }}</em>
                    </span>
                </h2>
                <ul>
                    <li>Again, currently happens on the individual carrier sites. </li>
                    <li>Surefy would be the future application for a single app for all carriers one day </li>
                    <li>Info captured in the needs analysis should auto populate into the surefy app “ideally” </li>
                </ul>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
//table data + search
export default {
    data() {
        return {

        }
    },
    components: {

    }
}
</script>