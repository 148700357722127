<template>
    <div class="q-comprehensive-filter">
		<v-menu
			v-if="activatorId"
			v-model="doShowMenu"
			:activator="`#${activatorId}`"
			offset-y
			:nudge-top="-5"
			:min-width="280"
			:close-on-content-click="false"
			:z-index="8"
			content-class="q-comprehensive-filter__content"
		>
			<template v-if="doShowMenu || doShowCurrentFilterCategory">
				<v-card v-if="!doShowCurrentFilterCategory" class="q-comprehensive-filter__card pa-2">
					<v-list class="q-comprehensive-filter__filters-list">
						<v-list-item
							v-for="(item, itemKey) in options" :key="itemKey"
							@click.stop="onFilterCategorySelect(itemKey)"
						>
							<v-list-item-icon>
								<v-icon v-if="item.icon" color="#ADADAD">{{ item.icon }}</v-icon>
							</v-list-item-icon>
							<v-list-item-title>{{ item.label }}</v-list-item-title>
							<div v-if="!item.isDefaultValue" class="q-comprehensive-filter__dot ml-4" />
						</v-list-item>
					</v-list>
				</v-card>

				<v-card v-else-if="doShowCurrentFilterCategory && currentFilterCategoryItem" class="q-comprehensive-filter__card pa-4">
					<div class="q-comprehensive-filter__filters-label">{{ currentFilterCategoryItem.label }}</div>
					<QPolicyTableStatFilter
						v-if="currentFilterCategoryId === 'statType'"
						v-model="currentFilterCategoryValue"
						:color="color"
					/>
					<QDatePeriodFilter
						v-else-if="options[currentFilterCategoryKey].type === 'datePeriod'"
						v-model="currentFilterCategoryValue"
						:show-title="false"
						:color="color"
						:z-index="9"
					/>
					<component
						v-else-if="currentFilterCategoryItem.component"
						:is="currentFilterCategoryItem.component"
						v-model="currentFilterCategoryValue"
						:options="currentFilterCategoryItem.options"
						:color="color"
					/>
					<div class="q-comprehensive-filter__filters-actions">
						<v-btn :class="actionBtnClass" outlined :color="color" @click="closeCurrentFilterCategoryMenu">
							Cancel
						</v-btn>
						<v-btn :class="actionBtnClass" :disabled="!currentFilterCategoryValueChanged" :color="color" @click="updateCurrentFilterCategoryValue">
							{{ currentFilterCategoryItem.actionLabel || 'Add' }}
						</v-btn>
					</div>
				</v-card>
			</template>
		</v-menu>

		<div v-if="filterTags.length" class="q-comprehensive-filter__filters-container">
			<template v-for="filter in filterTags">
				<v-chip
					:key="filter.key"
					close outlined label
					color="#707070"
					close-icon="icon-x-close-q"
					class="q-comprehensive-filter__filters-chip"
					@click="onFilterCategorySelect(filter.key)"
					@click:close="resetFilter(filter.key)"
				>
					<strong>{{ options[filter.key].label }}</strong>: {{ getFilterLabeledValue(filter.key, filter.value) }}
				</v-chip>
			</template>
			<v-btn class="q-comprehensive-filter__reset-btn" text :color="color" @click="resetFilters">
				Clear All
			</v-btn>
		</div>
    </div>
</template>

<script>
import QDatePeriodFilter from '@/components/utils/QDatePeriodFilter.vue'
import QComprehensiveFiltersCheckboxes from '@/components/utils/QComprehensiveFiltersCheckboxes.vue'
import QPolicyTableStatFilter from '@/components/datatables/QPolicyTableStatFilter.vue'

const statTypeOptions = [
	{ label: 'Total Agency', value: 'TotalAgency' },
	{ label: 'Baseshop', value: 'Baseshop' },
	{ label: 'Personal', value: 'PersonalProduction' },
]

export default {
    components: {
		QPolicyTableStatFilter,
		QDatePeriodFilter,
		QComprehensiveFiltersCheckboxes,
	},
    props: {
        value: { type: Object, required: true },
        filtersData: { type: Object, required: true  },
        filterKeys: { type: Array, default: () => [] },
        defaultValue: { type: Object },
		activatorId: { type: String, default: '' },
        actionBtnClass: { type: String, default: 'action-btn' },
        color: { type: String, default: '#46c3b2' },
    },
    data () {
        return {
			doShowMenu: false,
			doShowCurrentFilterCategory: false,
			currentFilterCategoryKey: '',
			currentFilterCategoryValue: null,
            filters: {},
            defaultFilters: {},
            filterTagsOrder: [],
        }
    },
    created () {
		this.getDefaultFilters()
		this.getFilters()
		this.getFilterTagsOrder()
    },
    computed: {
        options () {
            return this.filterKeys
				.reduce((acc, key) => {
					const item = this.getOptions(key)
					if (item) {
						acc[key] = item
					}
					return acc
				}, {})
        },
		currentFilterCategoryId () {
			if (!this.currentFilterCategoryKey) { return null }
			return this.options[this.currentFilterCategoryKey]?.id
		},
		currentFilterCategoryItem () {
			if (!this.currentFilterCategoryKey) { return null }
			let component = null
			if (this.currentFilterCategoryId === 'statType') {
				component = QPolicyTableStatFilter
			}
			if (this.options[this.currentFilterCategoryKey].type === 'datePeriod') {
				component = QDatePeriodFilter
			}
			if (this.options[this.currentFilterCategoryKey].type === 'checkboxes') {
				component = QComprehensiveFiltersCheckboxes 
			}
			return {
				...this.options[this.currentFilterCategoryKey],
				component,
			}
		},
		currentFilterCategoryValueChanged () {
			return this.checkFilterValueChanges(this.currentFilterCategoryValue, this.filters[this.currentFilterCategoryKey])
		},
		isDefaultFilters () {
			return Object.keys(this.filters)
				.every((key) => !this.checkFilterValueChanges(this.filters[key], this.defaultFilters[key]))
		},
		filterTags () {
			return this.filterTagsOrder.map((key) => ({ key, value: this.filters[key] }))
		},
    },
    watch: {
		doShowMenu (val) {
			if (!val) {
				this.currentFilterCategoryKey = ''
				this.doShowCurrentFilterCategory = false
			}
			this.$emit('menu-update', val)
		},
		currentFilterCategoryKey (val) {
			this.currentFilterCategoryValue = val
				? this.filters[val]
				: null
		},
        filters: {
			deep: true,
			handler (val) {
			   this.$emit('input', val)
		   },
		},
		isDefaultFilters: {
			immediate: true,
			handler (val) {
				this.$emit('default-filters', val)
			},
		},
		// $route (to) {
		// 	if (to.name !== 'Applications') { return }
        //     if (to.params && to.params.carrier_id > 0) {
        //         this.defaultFilters.Carrier = to.params.carrier_id
        //         this.resetFilters()
        //     }
        // },
    },
    methods: {
		getDefaultFilters () {
			const filters = this.defaultValue || this.value
			this.defaultFilters = this.copyFilters(filters)
		},
		getFilters () {
			this.filters = this.copyFilters(this.value)
		},
		getFilterTagsOrder () {
			this.filterTagsOrder = this.filterKeys
				.map((key) => this.checkFilterValueChanges(this.filters[key], this.defaultFilters[key]))
				.filter(Boolean)
		},
        getOptions (key) {
			if (this.filtersData?.[key]) {
				return {
					...this.filtersData[key],
					isDefaultValue: !this.checkFilterValueChanges(this.filters[key], this.defaultFilters[key]),
				}
            }
            return undefined
        },
		setFilterDefaultValue (key) {
			this.filters[key] = this.defaultFilters[key]
        },
		onFilterCategorySelect (key) {
			this.doShowMenu = true
			this.currentFilterCategoryKey = key
			this.doShowCurrentFilterCategory = true
		},
		updateCurrentFilterCategoryValue () {
			this.filters[this.currentFilterCategoryKey] = this.currentFilterCategoryValue
			this.updateFilterTagsOrder(this.currentFilterCategoryKey)
			this.currentFilterCategoryKey = ''
			this.doShowCurrentFilterCategory = false
			this.doShowMenu = false
		},
		closeCurrentFilterCategoryMenu () {
			this.currentFilterCategoryKey = ''
			this.doShowCurrentFilterCategory = false
		},
		updateFilterTagsOrder (key) {
			const isDefaultValue = !this.checkFilterValueChanges(this.filters[key], this.defaultFilters[key])
			if (this.filterTagsOrder.includes(key)) {
				if (isDefaultValue) {
					const index = this.filterTagsOrder.indexOf(key)
					this.filterTagsOrder = [
						...this.filterTagsOrder.slice(0, index),
						...this.filterTagsOrder.slice(index + 1),
					]
				}
			} else {
				if (!isDefaultValue) {
					this.filterTagsOrder = [...this.filterTagsOrder, key]
				}
			}
		},
		checkFilterValueChanges (changed, original) {
			// objects
			if (
				typeof changed === 'object' && changed !== null &&
				typeof original === 'object' && original !== null
			) {
				const changedKeys = Object.keys(changed)
				const originalKeys = Object.keys(original)
				const hasSameLength = changedKeys.length === originalKeys.length
				const hasSameValues = changedKeys.every((key) => changed[key] === original[key])
				if (hasSameLength && hasSameValues) { return false }
				return true
			}
			// strings
			return changed !== original
		},
		copyFilters (filters = {}) {
			return Object.entries(filters).reduce((acc, [key, value]) => {
				const copy = (typeof value === 'object' && value !== null) ? { ...value } : value
				acc[key] = copy
				return acc
			}, {})
		},
		getFilterLabeledValue (key, filter) {
			if (this.options[key].type === 'datePeriod') {
				const startDate = filter.startDate
					? this.formatDate(filter.startDate, 'MM/DD/YYYY')
					: null
				const endDate = filter.endDate
					? this.formatDate(filter.endDate, 'MM/DD/YYYY')
					: this.formatToday('MM/DD/YYYY')
				return (startDate && endDate)
					? `${startDate} - ${endDate}`
					: `by ${endDate}`
			}
			if (this.options[key].id === 'statType') {
				return statTypeOptions.find(({ value }) => value === filter)?.label || this.formatLabel(filter)
			}
			return filter
		},
        formatLabel (key) {
            return key.replace('_id', '')
                .replace('_switch', '')
                .replace(/^Is([A-Z]+)/g, '$1')
                .replaceAll('_', ' ')
                .replace(/([A-Z]+)/g, ' $1')
				.replace(/([A-Z][a-z])/g, ' $1')
                .replace(' Ind', '')
				.trim()
        },

		// expose
		showMenu () {
			this.doShowMenu = true
		},
		hideMenu () {
			this.doShowMenu = false
		},
		resetFilter (key) {
			this.setFilterDefaultValue(key)
			this.updateFilterTagsOrder(key)
		},
		resetFilters () {
			this.filterKeys.forEach((key) => this.setFilterDefaultValue(key))
			this.filterTagsOrder = []
        },
    },
}
</script>

<style lang="scss">
.q-comprehensive-filter__content {
	border-radius: 8px;
	box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
}

.q-comprehensive-filter__card {
	border-radius: inherit !important;
	border: 1px solid #E4E4E4 !important;
	background-color: #FFF !important;
}

.q-comprehensive-filter__filters-list {
	padding: 0;

	.v-list-item {
		padding: 0 0.75rem 0 0.5rem;

		&::before,
		&::after {
			border-radius: 6px;
		}
	}

	.v-list-item__icon {
		margin: 14px 1rem 14px 0 !important;
	}
}

.q-comprehensive-filter__dot {
	$size: 8px;
	flex: 0 0 auto;
	width: $size;
	height: $size;
	border-radius: $size / 2;
	background-color: #079455;
}

.q-comprehensive-filter__filters-actions {
	margin-top: 1.5rem;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 0.5rem 1rem;
}

.q-comprehensive-filter__filters-label {
	color: #707070;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.5;
}
</style>

<style lang="scss" scoped>
.q-comprehensive-filter {
	.q-comprehensive-filter__filters-container {
		display: flex;
		flex-wrap: wrap;
		gap: 0.5rem 1rem;
	}

	.q-comprehensive-filter__filters-chip {
		&.v-chip {
			background-color: #F8F8F8 !important;
			border-radius: 6px !important;
			border: 1px solid #E4E4E4 !important;
		}
	}

	.q-comprehensive-filter__reset-btn {
		height: 32px;
		text-transform: none;
		letter-spacing: normal;
		font-weight: 700;
		
		::v-deep .v-btn__content {
			line-height: 1.2;
			border-bottom: 2px solid currentColor;
		}
	}
}
</style>
