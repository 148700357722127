import { render, staticRenderFns } from "./QPlacedPolicyDataTable.vue?vue&type=template&id=7e1ca574&scoped=true&"
import script from "./QPlacedPolicyDataTable.vue?vue&type=script&lang=js&"
export * from "./QPlacedPolicyDataTable.vue?vue&type=script&lang=js&"
import style0 from "./QPlacedPolicyDataTable.vue?vue&type=style&index=0&id=7e1ca574&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e1ca574",
  null
  
)

export default component.exports