<template>
    <v-container fluid>
        <v-row cols="12" class="d-flex ">
            <v-col cols="12">
                <p class="q-display-2 q_dark_gray--text">Contact Information</p>
            </v-col>
            <v-card flat style="width:550px; max-width:90%;">
                <v-simple-table>
                    <tbody>
                        <tr>
                            <td>
                                <v-text-field v-model="clientContact.InsuredName" prepend-icon="fas fa-user" label="Full Name of Insured"></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <v-text-field v-model="clientContact.Email" prepend-icon="fas fa-envelope" label="Email"></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <v-text-field v-model="clientContact.Phone" prepend-icon="fas fa-phone" label="Phone"></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <v-list-item-title class="my-5">
                                    <v-icon class="pr-3">fas fa-map-marker-alt</v-icon> Address
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    <v-text-field v-model="clientContact.Street1" label="Street 1" class="ml-8"></v-text-field>
                                    <v-text-field v-model="clientContact.Street2" label="Street 2" class="ml-8"></v-text-field>
                                    <v-row class="d-flex ml-8">
                                        <div class=" flex-grow-1" style="width:250px; max-width:50%;">
                                            <v-text-field v-model="clientContact.City" label="City"></v-text-field>
                                        </div>
                                        <div class=" flex-shrink-1" style="width:100px; max-width:20%;">
                                            <v-select :items="States" v-model="clientContact.States" label="State" class="pl-5 flex-shrink-1"></v-select>
                                        </div>
                                        <div class=" flex-shrink-1" style="width:150px; max-width:30%;">
                                            <v-text-field v-model="clientContact.Zip" label="Zip" class="pl-5 flex-shrink-1"></v-text-field>
                                        </div>
                                    </v-row>
                                </v-list-item-subtitle>
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-card>
        </v-row>
    </v-container>
</template>
<script>
import { formatField } from '@/components/utils/FormatNeedsAnalysisFields'
export default {
    data: () => {
        return {
            AssetTypes: [
                "401k", "403b", "457", "IRA", "Pension", "Annuity", "CD", "Savings", "Investments"
            ],
            States: [
                'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY'
            ],
        }
    },
    mounted: function() {

    },
    methods: {

    },
    props: ['clientContact'],
    watch: {
        // 'clientContact': function(newV) {

        // }
    }
}

</script>
