import { getInstance } from "@/auth/index";
const request = require('superagent');
import { debounce } from 'vue-debounce'


export default {
    current_request: {},
    request_q: {},


    getVMToken() {
        var opts = {
            domain: 'auth-dev.quility.com',
            audience: 'https://api.virtualmentor.us/',
            scope: 'openid email profile',
            redirect_uri: process.env.VUE_APP_BASE_URL + '/admin/linkaccounts'
        };
        return new Promise((resolve, reject) => {
            getInstance().getTokenSilently(opts).then(function(token) {
                resolve(token);
            }).catch(function(err) {
                reject(err);
            })
        })
    },


    submitPolicySearch(options) {
        var g = this
        console.log(options)
        var product_cats = this.mapIDs(options.purpose);
        product_cats = this.mapIDs(options.policy_type, product_cats);
        product_cats = this.mapIDs(options.riders, product_cats);
        product_cats = this.mapIDs(options.underwriting, product_cats);
        var params = {
            ages: options.age,
            state_id: options.state.id,
            weight: options.weight,
            height: options.height_feet + "." + options.height_inches,
            tabacco_id: options.tobacco_use.id,
            product_second_cats: product_cats.join(","),
            arr: 1,
            table_content: 'T2'
        }
        return new Promise((resolve, reject) => {
            this.getVMToken().then(function(token) {
                g.current_request = request
                    .post(process.env.VUE_APP_VIRTUAL_MENTOR_URL + '/search/advance')
                    .type('form')
                    .send(params)
                    .set('Authorization', `Bearer ${token}`)
                    .accept('json')
                g.current_request.then(function(result) {
                    resolve(JSON.parse(result.text))
                }).catch(err => {
                    console.log(err.message, err.response)
                    reject(err)
                });
            }).catch(err => {
                console.log(err.message, err.response)
                reject(err)
            })
        });
    },

    mapIDs: function(arr, r) {
        if (typeof r == 'undefined') {
            r = [];
        }
        for (var i = 0; i < arr.length; i++) {
            r.push(arr[i].id)
        }
        return r;
    },

    pushQ(q, request, prom) {
        if (typeof q != 'undefined') {
            this.request_q[q] = request;
        }
    },

    pullQ(q) {
        if (typeof q != 'undefined') {
            this.request_q[q] = null;
        }
    },

    cancelQRequest(q) {
        if (typeof this.request_q[q] != 'undefined' && this.request_q[q] != null && typeof this.request_q[q].abort != 'undefined') {
            this.request_q[q].abort();
        }
    },

    getRequest(url, params, q) {
        var g = this
        return new Promise((resolve, reject) => {
            g.config().then(function(token) {
                g.current_request = request
                    .get(url)
                    .query(params)
                    .set('Content-Type', 'application/json')
                    .set('Authorization', `Bearer ${token}`)
                    .set('Accept', 'application/json')
                    .type('json')
                g.pushQ(q, g.current_request, this)

                g.current_request.then(function(result) {
                        g.pullQ(q)
                        if (result.body != null) {
                            resolve(result.body);
                        }
                        resolve(result.text);
                    })
                    .catch(function(err) {
                        if (err.status == 404) {
                            var msg = {
                                msg: err.response.body.message,
                                errors: "Page not found"
                            }
                            reject(msg);
                            return;
                        }
                        if (err.error) {
                            var error_message = "";
                            Object.keys(err.response.body.errors).forEach(function(k) {
                                error_message = error_message + err.response.body.errors[k] + " ";
                            })
                            var msg = {
                                msg: err.response.body.message,
                                errors: error_message
                            }
                            //g.showError("Whoops! " + msg)
                            reject(msg);
                        }
                        reject(err);
                    });;
            })
        })
    },

    postRequest(url, data) {
        var g = this
        return new Promise((resolve, reject) => {
            g.config().then(function(token) {
                request
                    .post(url, data)
                    //.set('Content-Type', 'application/json')
                    .set('Authorization', `Bearer ${token}`)
                    .set('Accept', 'application/json')
                    .set('Content-Type', 'application/json')
                    .type('json')
                    //.type('json')
                    //.on('error', function(err, n) {
                    //    console.log("EERRRRRRORR")
                    //    console.log(err, n);
                    //    reject(err);
                    //})
                    .then(function(result) {
                        resolve(result.body);
                    })
                    .catch(function(err) {
                        if (err.response) {
                            var error_message = "";
                            Object.keys(err.response.body.errors).forEach(function(k) {
                                error_message = error_message + err.response.body.errors[k] + " ";
                            })
                            var msg = {
                                msg: err.response.body.message,
                                errors: error_message
                            }
                            //g.showError("Whoops! " + msg)
                            reject(msg);
                        }
                    });
            })
        })
    },

    putRequest(url, data) {
        var g = this
        return new Promise((resolve, reject) => {
            g.config().then(function(token) {
                request
                    .put(url, data)
                    .set('Content-Type', 'application/json')
                    .set('Authorization', `Bearer ${token}`)
                    .set('Content-Type', 'application/json')
                    .set('Accept', 'application/json')
                    .type('json')
                    .then(function(result) {
                        resolve(result.body);
                    })
                    .catch(function(err) {
                        if (err.response) {
                            var error_message = "";
                            Object.keys(err.response.body.errors).forEach(function(k) {
                                error_message = error_message + err.response.body.errors[k] + " ";
                            })
                            var msg = {
                                msg: err.response.body.message,
                                errors: error_message
                            }
                            //g.showError("Whoops! " + msg)
                            reject(msg);
                        }
                    });
            })
        })
    },

    deleteRequest(url) {
        var g = this
        return new Promise((resolve, reject) => {
            g.config().then(function(token) {
                request
                    .delete(url)
                    .set('Content-Type', 'application/json')
                    .set('Authorization', `Bearer ${token}`)
                    .set('Content-Type', 'application/json')
                    .set('Accept', 'application/json')
                    .type('json')
                    .then(function(result) {
                        resolve(result.body);
                    })
                    .catch(function(err) {
                        if (err.response) {
                            var error_message = "";
                            Object.keys(err.response.body.errors).forEach(function(k) {
                                error_message = error_message + err.response.body.errors[k] + " ";
                            })
                            var msg = {
                                msg: err.response.body.message,
                                errors: error_message
                            }
                            //g.showError("Whoops! " + msg)
                            reject(msg);
                        }
                    });
            })
        })
    },

}
