<template>
    <v-container fluid class="q-sticky-buffer">
        <!-- help and wayfinding -->
                <v-col cols="12" class="text-right">
                    <v-bottom-sheet v-model="sheet">
                        <template v-slot:activator="{ on }">
                            <v-btn icon color="pink" v-on="on">
                              <v-icon>fas fa-question-circle</v-icon>
                            </v-btn>
                        </template>
                        <v-sheet class="text-left px-7" height="auto">
                            <div class="py-3" style="max-width:900px; margin:0 auto;">
                                <p class="q-display-2">Policy Finder</p>
                                <p>This section mirrors the functions of the Policy Finder from Virtual Mentor.</p>
                                    <ul>
                                        <li>Client info can be pushed to this form from the Leads section</li>
                                        <li>Leads can be searched and have the client data pulled into the form</li>
                                        <li>Follow the steps to find suggested policies for your client</li>
                                        <li>Click the down arrow left the policy name for policy specific info or to beging the application process</li>
                                    </ul>
                                <v-btn class="mt-6" flat color="error" @click="sheet = !sheet">close</v-btn>
                            </div>
                        </v-sheet>
                    </v-bottom-sheet>
                </v-col>
                <!-- /help and wayfinding -->
        <v-card cols="12" class="mx-3" elevation="3" height="100%">
            <v-col cols="12" class="q_new_b_1  pl-1 py-0">
                <p class="q-display-3 pl-5 py-2 white--text">
                    Policy Finder
                    <span v-if="lead">
                        for <em>{{ lead.Applicant }}</em>
                    </span>
                </p>
            </v-col>
            <v-row class=" mx-5 px-3 pt-5" justify="start" align="stretch">
                <v-col cols="12" sm="4" class="">
                    <v-overflow-btn class="my-2" :items="dropdown_font" label="Location" target="#dropdown-example-1"></v-overflow-btn>
                </v-col>
            </v-row>
            <v-row class="mx-5 px-3 grey lighten-4" justify="start" align="stretch">
                <v-col cols="3">
                    <div style="display:inline-block; max-width:30%; height:40px;"> Height:</div>
                    <div style="display:inline-block;width:80px;  max-width:30%;height:40px; margin-left:2em;">
                        <v-select :items="feet" label="feet"></v-select>
                    </div>
                    <div style="display:inline-block; width:80px; max-width:30%;height:40px; margin-left:2em;">
                        <v-select :items="inches" label="inches"></v-select>
                    </div>
                </v-col>
                <v-col cols="3">
                    <div style="display:inline-block; max-width:30%; height:40px;">Weight:</div>
                    <div style="display:inline-block;width:80px;  max-width:30%;height:40px; margin-left:2em;">
                        <v-text-field label="lbs" single-line></v-text-field>
                    </div>
                </v-col>
                <v-col cols="3">
                    <div style="display:inline-block; max-width:30%; height:40px;">Age:</div>
                    <div style="display:inline-block;width:80px;  max-width:30%;height:40px; margin-left:2em;">
                        <v-text-field label="" single-line></v-text-field>
                    </div>
                </v-col>
            </v-row>
            <v-row class="mx-5 px-3 pb-3" justify="start" align="stretch">
                <v-col cols="3">
                    <div class="pt-5" style="display:inline-block; height:40px;">
                        Tobacco Use:
                    </div>
                </v-col>
                <v-col cols="6">
                    <div style="display:inline-block; height:40px;">
                        <v-radio-group v-model="tobacco" row>
                            <v-radio label="None" value="radio-3"></v-radio>
                            <v-radio label="Smoker" value="radio-1"></v-radio>
                            <v-radio label="Chewing / Snuff / Other" value="radio-2"></v-radio>
                        </v-radio-group>
                    </div>
                </v-col>
            </v-row>
            <v-row class="mx-5 grey px-3 lighten-4" justify="start" align="stretch">
                <v-col cols="3">
                    <div class="pt-5" style="display:inline-block; height:40px;">
                        Optional Filters:
                    </div>
                </v-col>
                <v-col cols="12" sm="9">
                    <v-row>
                        <v-col cols="12" sm="4">
                            <p class="title">Product</p>
                            <v-checkbox v-model="product" value="Mortgage Protection" label="Mortgage Protection"></v-checkbox>
                            <v-checkbox v-model="product" value="Final Expense" label="Final Expense"></v-checkbox>
                            <v-checkbox v-model="product" value="Debt Free Life" label="Debt Free Life"></v-checkbox>
                        </v-col>
                        <v-col cols=" 12" sm="4" class="">
                            <p class="title">Policy Type</p>
                            <v-checkbox v-model="policy_type" label="Term" value="Term"></v-checkbox>
                            <v-checkbox v-model="policy_type" label="Universal Life" value="Universal Life"></v-checkbox>
                            <v-checkbox v-model="policy_type" label="Whole Life" value="Whole Life"></v-checkbox>
                            <v-checkbox v-model="policy_type" label="Accidental Death" value="Accidental Death"></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <p class="title">Riders</p>
                            <v-checkbox v-model="riders" label="Critical Illness" value="Critical Illness"></v-checkbox>
                            <v-checkbox v-model="riders" label="Disability" value="Disability"></v-checkbox>
                            <v-checkbox v-model="riders" label="Return of Premium" value="Return of Premium"></v-checkbox>
                            <v-checkbox v-model="riders" label="Children" value="Children"></v-checkbox>
                            <v-checkbox v-model="riders" label="Spouse" value="Spouse"></v-checkbox>
                            <v-checkbox v-model="riders" label="Waiver of Preimum" value="Waiver of Preimum"></v-checkbox>
                            <v-checkbox v-model="riders" label="Accidental Death" value="Accidental Death"></v-checkbox>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row class="mx-5 px-3 " justify="start" align="stretch">
                <v-col cols="3">
                    <div class="pt-5" style="display:inline-block; height:40px;">
                        Health Status:<br /><span class="caption">check all that apply</span>
                    </div>
                </v-col>
                <v-col cols="12" sm="9">
                    <v-row>
                        <v-col cols="12" sm="4">
                            <v-checkbox v-model="health_status" label="Cancer" value="Cancer"></v-checkbox>
                            <v-checkbox v-model="health_status" label="Disabled (under age 65)" value="Disabled"></v-checkbox>
                            <v-checkbox v-model="health_status" label="Kidney" value="Kidney"></v-checkbox>
                            <v-checkbox v-model="health_status" label="Lung" value="Lung"></v-checkbox>
                        </v-col>
                        <v-col cols=" 12" sm="4" class="">
                            <v-checkbox v-model="health_status" label="Diabetes" value="Diabetes"></v-checkbox>
                            <v-checkbox v-model="health_status" label="Immune & Neurological" value="Immune & Neurological"></v-checkbox>
                            <v-checkbox v-model="health_status" label="Heart / Circulatory" value="Heart / Circulatory"></v-checkbox>
                            <v-checkbox v-model="health_status" label="Mental / Nervous Disorders" value="Mental / Nervous Disorders"></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-checkbox v-model="health_status" label="Digestive" value="Digestive"></v-checkbox>
                            <v-checkbox v-model="health_status" label="Joint & Muscle" value="Joint & Muscle"></v-checkbox>
                            <v-checkbox v-model="health_status" label="Liver" value="Liver"></v-checkbox>
                            <v-checkbox v-model="health_status" label="Other" value="Other"></v-checkbox>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row class="ma-5 px-3" justify="start" style="border-top:#cccccc thin solid;">
                <div class="my-2 py-5">
                    <v-btn x-large color="q_new_b_1" dark @click="ShowOverlay">Search Policy Options</v-btn>
                    <v-overlay :value="overlay">
                        <v-progress-circular indeterminate size="64"></v-progress-circular>
                    </v-overlay>
                </div>
            </v-row>
        </v-card>
        <v-card id="results" class="pa-5" cols="12" v-if="show_results">
            <v-row justify="start" align="stretch">
                <v-col cols="12">
                    <p class="q-display-3 pl-5 text--q_blue_1">Tier 1 Products</p>
                    <div class="ml-8 pl-4" style="border-left:#3F51B5 thick solid;">
                        <q-policy-finder-results-table title="Tier 1 Products" :rows="5">
                        </q-policy-finder-results-table>
                    </div>
                </v-col>
            </v-row>
            <v-row justify="start" align="stretch">
                <v-col cols="12">
                    <p class="q-display-3 pl-5 text--q_blue_1">Tier 2 Products</p>
                    <div class="ml-8 pl-4" style="border-left:#5C6BC0 thick solid;">
                        <q-policy-finder-results-table title="Tier 1 Products" :rows="5">
                        </q-policy-finder-results-table>
                    </div>
                </v-col>
            </v-row>
            <v-row justify="start" align="stretch">
                <v-col cols="12">
                    <p class="q-display-3 pl-5 text--q_blue_1">Tier 3 Products</p>
                    <div class="ml-8 pl-4" style="border-left:#7986CB thick solid;">
                        <q-policy-finder-results-table title="Tier 1 Products" :rows="5">
                        </q-policy-finder-results-table>
                    </div>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>
<script>
import QPolicyFinderResultsTable from '@/components/datatables/QPolicyFinderResultsTable.vue';
import Lead from '@/store/Models/Lead'

export default {
    data: () => ({
        lead_id: null,
        dropdown_font: ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'],
        feet: ['1', '2', '3', '4', '5', '6', '7', ],
        inches: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', ],
        overlay: false,
        show_results: false,
        tobacco: null,
        health_status: [],
        product: [],
        policy_type: [],
        riders: [],
        sheet: false,
    }),
    mounted: function() {
        this.reset();
    },
    computed: {
        lead: function() {
            if (Lead.find(this.lead_id) !== null) {
                this.$emit('lead', this.lead_id);
                return Lead.find(this.lead_id);
            }
            return {};
        }
    },
    methods: {
        ShowOverlay: function() {
            this.overlay = !this.overlay;
            var g = this
            setTimeout(
                function() {
                    g.overlay = false
                    g.show_results = true
                    g.$nextTick(function() {
                        g.$vuetify.goTo("#results")
                    });
                },
                1000
            )
        },
        reset: function() {
            this.lead_id = this.$route.params.lead_id;
            this.show_results = false
            this.tobacco = null
            this.health_status = []
            this.product = []
            this.policy_type = []
            this.riders = []
        }
    },
    watch: {
        '$route.params.lead_id': function(newV, oldV) {
            if (typeof newV != 'undefined' && this.lead_id != newV) {
                this.reset();
            }

        }
    },
    components: {
        QPolicyFinderResultsTable,

    }
}
</script>