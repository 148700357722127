<template>
    <v-container fluid>
        <v-card outlined class="pa-5 my-5 site-width" v-if="!showQRS && !showOverPayment && !showDFLScript && !showEmailMessage">
            <h3>Results</h3>
            <v-row>
                <v-col class="ma-2 px-4" md="8">
                    <p>At this time mortgage protection is the best solution for your client. We will review the file in a year for the PCS program</p>
                    <v-btn dark color="q_new_b_1" depressed @click="triggerEmail">
                        Email Results<v-icon class="pl-3">fas fa-envelope</v-icon>
                    </v-btn>
                </v-col>
                <v-col class="ma-2 px-4" style="background-color:#FFF9C4;">
                    <p> Notes:</p>
                    <v-textarea outlined v-model="results.MPNotes"></v-textarea>
                </v-col>
            </v-row>
        </v-card>
        <v-card outlined class="pa-5 my-5 site-width" v-if="showEmailMessage">
            <div v-if="emailStatus == 1">
                <h4 class="text-center py-12">Your results have been emailed. Thank you!</h4>
            </div>
            <div v-if="emailStatus == -1">
                There was an issue sending your email.
            </div>
        </v-card>
        <v-card outlined class="pa-5 my-5 site-width" v-if="showQRS">
            <h3 class="q-display-2 q_dark_gray--text">QRS Reset Survey </h3>
            <div v-if="CurrentQRSQuestion">
                <!-- results.QRSOptions: {{ results.QRSOptions[CurrentQRSQuestion] }} -->
                <p v-if="results.QRSOptions[CurrentQRSQuestion].MainQuestion">
                    {{ results.QRSOptions[CurrentQRSQuestion].Quesition }}
                </p>
                <p v-else>{{ results.QRSOptions[CurrentQRSQuestion].Quesition }}</p>
                <div class="d-inline-block " v-for="(answerInfo, index) in results.QRSOptions[CurrentQRSQuestion].Answers" :key=index>
                    <div v-if="answerInfo.Field" class="px-5 q_border_right_1">
                        <v-text-field v-model="results[answerInfo.Field]" :prepend-icon=answerInfo.Icon :label=answerInfo.FieldLabel></v-text-field>
                        <v-btn v-if="answerInfo.Field" outlined color="q_new_b_1" @click="UpdateQuestion(answerInfo.NextQuestion)">
                            {{ answerInfo.ButtonLabel }}
                        </v-btn>
                    </div>
                    <div v-else class="px-5 ">
                        <v-btn outlined color="q_new_b_1" @click="UpdateQuestion(answerInfo.NextQuestion)">
                            {{ answerInfo.Label }}
                        </v-btn>
                    </div>
                </div>
            </div>
            <div v-if="showQRSResetScript">
                <v-row>
                    <v-col class="ma-2 px-4" md="8">
                        <h5>Reset Script</h5>
                        <p>We have a retirement division who’s primary focus is education. Their job is to make sure that you actually understand the vehicle that’s supposed to provide you income for the rest of your life! They’ll get you the information, and you do with it whatever you want. If I said same time next week, that would work for the two of you right.</p>
                        <v-btn dark color="q_new_b_1" depressed @click="triggerEmail">
                            Email Results<v-icon class="pl-3">fas fa-envelope</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col class="ma-2 px-4" style="background-color:#FFF9C4;">
                        <p> Notes:</p>
                        <v-textarea outlined v-model="results.QRSNotes"></v-textarea>
                    </v-col>
                </v-row>
            </div>
        </v-card>
        <v-card class="pa-5 my-5 site-width" outlined v-if="showOverPayment">
            <p class="q-display-2 q_dark_gray--text">DFL Rest Survey</p>
            <div v-if="!DFLEnd">
                <div v-for="(question, key) in results.OverPaymentOptions" :key="key">
                    <v-row v-if="question.PreviousQuestion == null || results[question.PreviousQuestion]">
                        <v-col cols="8">
                            {{ question.Quesition }}
                        </v-col>
                        <v-col cols="4">
                            <v-radio-group v-model="results[key]" row>
                                <v-radio value="Yes" label="Yes"></v-radio>
                                <v-radio value="No" label="No"></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                </div>
            </div>
            <div v-if="showDFLScript">
                <v-row>
                    <v-col class="ma-2 px-4" md="8">
                        <h5>Debt Free Life Script</h5>
                        <p>We have a division who’s primary focus is debt payoff. They can educate you on how you could potentialy payoff all your debt, and build wealth without spending any additional money. Having your mortgage and your debt payed off so you could have a more comfortable retirment is their goal. They'll get you the information and do with it what ever you want. If I said "same time next week", that would work for the two of you, right?</p>
                        <v-btn dark color="q_new_b_1" depressed @click="triggerEmail">
                            Email Results<v-icon class="pl-3">fas fa-envelope</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col class="ma-2 px-4" style="background-color:#FFF9C4;">
                        <p> Notes:</p>
                        <v-textarea outlined v-model="results.DFLNotes"></v-textarea>
                    </v-col>
                </v-row>
            </div>
            <div v-if="showDFLThanks">
                <h4>Debt Free Life Script</h4>
                <p>Thank you for your time. Please let me know if anything changes and we can review your position.</p>
                <v-btn dark color="q_new_b_1" depressed @click="triggerEmail">
                    Email Results<v-icon class="pl-3">fas fa-envelope</v-icon>
                </v-btn>
            </div>
        </v-card>
    </v-container>
</template>
<script>
export default {
    data() {
        return {
            CurrentQRSQuestion: "Q1",
            CurrentOPQuestion: "Q1",
            showQRSResetScript: false
        }
    },
    mounted: function() {},
    methods: {
        calculateDisplay: function(mortgages, assets, creditcards, studentloans, personal, carloan, q1, q2, q3) {
            let showQRS = false
            let showOverPayment = false

            let totalAdditionalAssets = 0
            let totalAccelerated = 0
            let overallTotal = 0

            for (const mortgate of mortgages) {
                totalAccelerated += parseInt(mortgate.acceleratedAmount)
            }

            for (const asset of assets) {
                totalAdditionalAssets += parseInt(asset.current_value)
            }

            // get cc total
            for (const ccData of creditcards) {
                if (ccData.Overpayment) {
                    overallTotal++
                    break
                }
            }

            // get student loan total
            for (const slData of studentloans) {
                if (slData.Overpayment) {
                    overallTotal++
                    break
                }
            }

            // get personal total
            for (const perData of personal) {
                if (perData.Overpayment) {
                    overallTotal++
                    break
                }
            }

            // get car loan total
            for (const clData of carloan) {
                if (clData.Overpayment) {
                    overallTotal++
                    break
                }
            }

            showQRS = (totalAdditionalAssets >= 100000)
            showOverPayment = (overallTotal >= 1 || totalAccelerated >= 250)

            if (showOverPayment && showQRS) {
                if (q1 === "No" && q2 === "No" && q3 === "No") {
                    showOverPayment = false
                } else {
                    showQRS = false
                }
            }

            return {
                "showOverPayment": showOverPayment,
                "showQRS": showQRS
            }
        },
        UpdateQuestion: function(newQuestion) {

            if (newQuestion === "Q100") {
                this.showQRSResetScript = true
                this.results.isQRSReset = true
                this.CurrentQRSQuestion = null
            } else {
                this.showQRSResetScript = false
                this.CurrentQRSQuestion = newQuestion
            }
        },
        UpdateOPQuestion: function(newQuestion) {
            // alert(newQuestion)
            this.CurrentOPQuestion = newQuestion
        },
        triggerEmail: function() {
            this.$emit('send-email');
        }
    },
    computed: {
        showQRS: function() {

            const show = this.calculateDisplay(
                this.assets.Mortgage,
                this.assets.Assets,
                this.debt.CreditCardDebt,
                this.debt.StudentLoanDebt,
                this.debt.PersonalDebt,
                this.debt.CarLoanDebt,
                this.results.Q1,
                this.results.Q2,
                this.results.Q3
            )

            return show.showQRS
        },
        showOverPayment: function() {
            const show = this.calculateDisplay(
                this.assets.Mortgage,
                this.assets.Assets,
                this.debt.CreditCardDebt,
                this.debt.StudentLoanDebt,
                this.debt.PersonalDebt,
                this.debt.CarLoanDebt,
                this.results.Q1,
                this.results.Q2,
                this.results.Q3
            )

            return show.showOverPayment
        },
        showDFLScript: function() {
            return this.DFLEnd && (this.results.Q1 == "Yes" || this.results.Q2 == "Yes" || this.results.Q3 == "Yes")
        },
        showDFLThanks: function() {
            return this.results.Q1 == "No" && this.results.Q2 == "No" && this.results.Q3 == "No"
        },
        DFLEnd: function() {
            return this.results.Q1 != null && this.results.Q2 != null && this.results.Q3 != null
        },
        showEmailMessage: function() {
            return this.emailStatus == 1 || this.emailStatus == -1
        }
    },
    props: ['results', 'debt', 'assets', 'emailStatus'],
}

</script>
