<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-my-pending-policy-submenu></q-my-pending-policy-submenu>
        <v-row wrap align="stretch" class="d-flex mx-5">
            <v-col cols=12>
                <v-card>
                    <q-pending-policy-data-table :for_baseshop="1" :agent="user.Agent" :rows="25" title="Baseshop Pending Policies"></q-pending-policy-data-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
//table data + search
import QPendingPolicyDataTable from '@/components/datatables/QPendingPolicyDataTable.vue';
import QMyPendingPolicySubmenu from '@/components/navigation/Submenus/QMyPendingPolicySubmenu.vue';

export default {
    data() {
        return {
            search: '',
        }
    },
    components: {
        QPendingPolicyDataTable,
        QMyPendingPolicySubmenu
    },
}

</script>
