<template>
    <v-container fluid>
        <v-row cols="12" class="d-flex justify-space-around ">
            <v-col cols="12">
                <p class="q-display-2 q_dark_gray--text">Income</p>
                <p><strong>Income, Sources and Providing for the Future</strong></p>
            </v-col>
        </v-row>
        <v-card cols="12" class="pa-5 ma-2 mt-7 d-flex justify-center flex-wrap">
            <v-card flat cols="12" sm="3" class="pa-5 ma-2 flex-grow-1 flex-shrink-1">
                <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-title>Client Annual Income</v-list-item-title>
                        <v-list-item-subtitle>
                            <v-text-field v-model="lead.Income.AnnualIncome" prepend-icon="fas fa-dollar-sign" label="employment and/or self-employment" @blur="updateFormat('AnnualIncome')" @focus="updateFormat('AnnualIncome')"></v-text-field>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-card>
            <v-card flat cols="12" sm="3" class="pa-5 ma-2 flex-grow-1 flex-shrink-1">
                <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-title>Social Security and Pension</v-list-item-title>
                        <v-list-item-subtitle>
                            <v-text-field v-model="lead.Income.Pension" prepend-icon="fas fa-dollar-sign" label="plus other gov income" @blur="updateFormat('Pension')" @focus="updateFormat('Pension')"></v-text-field>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-card>
            <v-card flat cols="12" sm="3" class="pa-5 ma-2 flex-grow-1 flex-shrink-1">
                <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-title>Investment Income </v-list-item-title>
                        <v-list-item-subtitle>
                            <v-text-field v-model="lead.Income.InvestmentIncome" prepend-icon="fas fa-dollar-sign" label="include rental income" @blur="updateFormat('InvestmentIncome')" @focus="updateFormat('InvestmentIncome')"></v-text-field>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-card>
        </v-card>
        <v-card cols="12" class="pa-5 ma-2 mt-7 d-flex justify-space-around flex-wrap">
            <v-card flat cols="12" sm="3" class="pa-5 ma-2 flex-grow-1 flex-shrink-0">
                <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-title>Spouse Annual Income</v-list-item-title>
                        <v-list-item-subtitle>
                            <v-text-field v-model="lead.Income.SpouseIncome" prepend-icon="fas fa-dollar-sign" label="employment and/or self-employment" @blur="updateFormat('SpouseIncome')" @focus="updateFormat('SpouseIncome')"></v-text-field>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-card>
            <v-card flat cols="12" sm="3" class="pa-5 ma-2 flex-grow-1 flex-shrink-0">
                <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-title>Spouse Social Security and Pension</v-list-item-title>
                        <v-list-item-subtitle>
                            <v-text-field v-model="lead.Income.SpousePension" prepend-icon="fas fa-dollar-sign" label="plus other gov income" @blur="updateFormat('SpousePension')" @focus="updateFormat('SpousePension')"></v-text-field>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-card>
        </v-card>
        <v-card cols="12" class="pa-5 ma-2 mt-7 d-flex ">
            <v-card flat cols="12" sm="3" class="pa-5 ma-2 flex-shrink-0">
                <v-list-item three-line>
                    <v-list-item-content>
                        <v-list-item-title>Income Replacement </v-list-item-title>
                        <v-list-item-subtitle>Number of years to replace lost income when client dies? </v-list-item-subtitle>
                        <v-list-item-subtitle>
                            <v-text-field v-model="lead.Income.ReplacementYears" prepend-icon="fas fa-calendar-alt" label="0-6"></v-text-field>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-card>
        </v-card>
    </v-container>
</template>
<script>
import { formatField } from '@/components/utils/FormatNeedsAnalysisFields'
export default {
    props: ['lead'],
    methods: {
        updateFormat: function(fieldName){
           this.lead.Income[fieldName] = formatField(fieldName,this.lead.Income)
        }
    }
}
</script>