<template>
    <v-container fluid>
        <v-row cols="12">
            <v-col cols="12">
                <v-card class="pa-5">
                    <v-col cols="12">
                        <p class="q-display-2 q_dark_gray--text">Life Insurance</p>
                        <p><strong>Insurance Values:</strong> Current insurance policies and their values</p>
                    </v-col>
                    <v-row class="d-flex justify-left">
                        <v-col cols="12" md="4" sm="6" flat class="px-5 flex-grow-1 flex-shrink-1">
                            <div class="q-display-1">Primary Life Insurance</div>
                            <v-overflow-btn v-model="lead.LifeInsurance.PrimaryInsuranceType" class="my-2" dense :items="insurance_type" label="type" target="#dropdown-example-1"></v-overflow-btn>
                            <v-text-field v-model="lead.LifeInsurance.PrimaryInsuranceCarrier" prepend-icon="fas fa-edit" label="carrier"></v-text-field>
                            <v-text-field v-model="lead.LifeInsurance.PrimaryInsuranceValue" prepend-icon="fas fa-dollar-sign" label="value" @blur="updateFormat('PrimaryInsuranceValue')" @focus="updateFormat('PrimaryInsuranceValue')"></v-text-field>
                            <v-text-field v-model="lead.LifeInsurance.PrimaryInsurancePremium" prepend-icon="fas fa-dollar-sign" label="premium" @blur="updateFormat('PrimaryInsurancePremium')" @focus="updateFormat('PrimaryInsurancePremium')"></v-text-field>
                            <v-text-field v-model="lead.LifeInsurance.PrimaryExpiresInYears" prepend-icon="fas fa-calendar-alt" label="Years to expiration"></v-text-field>
                            <v-text-field v-model="lead.LifeInsurance.PrimaryInsurancePurpose" prepend-icon="fas fa-umbrella" label="purpose"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4" sm="6" flat class="px-5 flex-grow-1 flex-shrink-1">
                            <div class="q-display-1">Insurance - other</div>
                            <v-overflow-btn v-model="lead.LifeInsurance.OtherInsuranceType" class="my-2" dense :items="insurance_type" label="type" target="#dropdown-example-1"></v-overflow-btn>
                            <v-text-field v-model="lead.LifeInsurance.OtherInsuranceCarrier" prepend-icon="fas fa-edit" label="carrier"></v-text-field>
                            <v-text-field v-model="lead.LifeInsurance.OtherInsuranceValue" prepend-icon="fas fa-dollar-sign" label="value" @blur="updateFormat('OtherInsuranceValue')" @focus="updateFormat('OtherInsuranceValue')"></v-text-field>
                            <v-text-field v-model="lead.LifeInsurance.OtherInsurancePremium" prepend-icon="fas fa-dollar-sign" label="preimum" @blur="updateFormat('OtherInsurancePremium')" @focus="updateFormat('OtherInsurancePremium')"></v-text-field>
                            <v-text-field v-model="lead.LifeInsurance.OtherExpiresInYears" prepend-icon="fas fa-calendar-alt" label="Years to expiration"></v-text-field>
                            <v-text-field v-model="lead.LifeInsurance.OtherInsurancePurpose" prepend-icon="fas fa-umbrella" label="purpose"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4" sm="6" flat class="px-5 flex-grow-1 flex-shrink-1">
                            <div class="q-display-1">Life Insurance Cash Value</div>
                            <v-text-field v-model="lead.LifeInsurance.CashValue" prepend-icon="fas fa-dollar-sign" label="sum all policies" @blur="updateFormat('CashValue')" @focus="updateFormat('CashValue')"></v-text-field>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-row cols="12">
            <v-col cols="12">
                <v-card>
                    <v-col cols="12">
                        <p class="q-display-2 q_dark_gray--text">Spouse Owned Policies</p>
                    </v-col>
                    <v-container>
                        <v-row>
                            <v-col flat class="px-5 flex-grow-1 flex-shrink-1">
                                <div class="q-display-1">Spouse Primary Life Insurance</div>
                                <!--
                                <v-text-field v-model="lead.LifeInsurance.SpousePrimaryInsuranceType" prepend-icon="fas fa-shield-alt" label="type"></v-text-field>
                            -->
                                <v-overflow-btn v-model="lead.LifeInsurance.SpousePrimaryInsuranceType" class="my-2" dense :items="insurance_type" label="type" target="#dropdown-example-1"></v-overflow-btn>
                                <v-text-field v-model="lead.LifeInsurance.SpousePrimaryInsuranceCarrier" prepend-icon="fas fa-edit" label="carrier"></v-text-field>
                                <v-text-field v-model="lead.LifeInsurance.SpousePrimaryInsuranceValue" prepend-icon="fas fa-dollar-sign" label="value" @blur="updateFormat('SpousePrimaryInsuranceValue')" @focus="updateFormat('SpousePrimaryInsuranceValue')"></v-text-field>
                                <v-text-field v-model="lead.LifeInsurance.SpousePrimaryInsurancePremium" prepend-icon="fas fa-dollar-sign" label="preimum" @blur="updateFormat('SpousePrimaryInsurancePremium')" @focus="updateFormat('SpousePrimaryInsurancePremium')"></v-text-field>
                                <v-text-field v-model="lead.LifeInsurance.SpousePrimaryExpiresInYears" prepend-icon="fas fa-calendar-alt" label="Years to expiration"></v-text-field>
                                <v-text-field v-model="lead.LifeInsurance.SpousePrimaryInsurancePurpose" prepend-icon="fas fa-umbrella" label="purpose"></v-text-field>
                            </v-col>
                            <v-divider vertical inset></v-divider>
                            <v-col flat class="px-5 flex-grow-1 flex-shrink-1">
                                <div class="q-display-1">Insurance - other</div>
                                <v-overflow-btn v-model="lead.LifeInsurance.SpouseOtherInsuranceType" class="my-2" dense :items="insurance_type" label="type" target="#dropdown-example-1"></v-overflow-btn>
                                <v-text-field v-model="lead.LifeInsurance.SpouseOtherInsuranceCarrier" prepend-icon="fas fa-edit" label="carrier"></v-text-field>
                                <v-text-field v-model="lead.LifeInsurance.SpouseOtherInsuranceValue" prepend-icon="fas fa-dollar-sign" label="value" @blur="updateFormat('SpouseOtherInsuranceValue')" @focus="updateFormat('SpouseOtherInsuranceValue')"></v-text-field>
                                <v-text-field v-model="lead.LifeInsurance.SpouseOtherInsurancePremium" prepend-icon="fas fa-dollar-sign" label="preimum" @blur="updateFormat('SpouseOtherInsurancePremium')" @focus="updateFormat('SpouseOtherInsurancePremium')"></v-text-field>
                                <v-text-field v-model="lead.LifeInsurance.SpouseOtherExpiresInYears" prepend-icon="fas fa-calendar-alt" label="Years to expiration"></v-text-field>
                                <v-text-field v-model="lead.LifeInsurance.SpouseOtherInsurancePurpose" prepend-icon="fas fa-umbrella" label="purpose"></v-text-field>
                            </v-col>
                            <v-divider vertical inset></v-divider>
                            <v-col flat cols="12" sm="4">
                                <div class="q-display-1">Spouse Life Insurance Cash Value</div>
                                <v-text-field v-model="lead.LifeInsurance.SpouseCashValue" prepend-icon="fas fa-dollar-sign" label="sum all policies"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
        <v-row cols="12">
            <v-col cols="12">
                <p class="q-display-2 q_dark_gray--text">Employer Owned Policies</p>
            </v-col>
            <v-col cols="12">
                <v-card>
                    <v-container>
                        <v-row>
                            <v-col flat cols="12" sm="6">
                                <div class="q-display-1">Client Employer Life Insurance</div>
                                <v-text-field v-model="lead.LifeInsurance.EmployerLifeInsurance" prepend-icon="fas fa-dollar-sign" label="value" @blur="updateFormat('EmployerLifeInsurance')" @focus="updateFormat('EmployerLifeInsurance')"></v-text-field>
                                <v-text-field v-model="lead.LifeInsurance.EmployerLifeExpiresInYears" label="Years to expiration" class="ml-9"></v-text-field>
                            </v-col>
                            <v-col flat cols="12" sm="6">
                                <div class="q-display-1">Spouse Employer Life Insurance - other</div>
                                <v-text-field v-model="lead.LifeInsurance.SpouseEmployerLifeInsurance" prepend-icon="fas fa-dollar-sign" label="value" @blur="updateFormat('SpouseEmployerLifeInsurance')" @focus="updateFormat('SpouseEmployerLifeInsurance')"></v-text-field>
                                <v-text-field v-model="lead.LifeInsurance.SpouseEmployerLifeExpiresInYears" label="Years to expiration" class="ml-9"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { formatField } from '@/components/utils/FormatNeedsAnalysisFields'
export default {
    props: ['lead'],
    data: () => ({
        insurance_type: ['Accidental Death', 'Group Term Life', 'Term Life', 'Universal Life', 'Whole Life'],
    }),
    methods: {
        updateFormat: function(fieldName){
           this.lead.LifeInsurance[fieldName] = formatField(fieldName,this.lead.LifeInsurance)
        }
    }
}

</script>
